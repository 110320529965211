import React, { useMemo, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useStyles } from './styles';
import SignatureCanvas from 'react-signature-canvas';

const Signature = ({ image, onChange }) => {
  const classes = useStyles();
  const signatureCanvas = useRef({});
  const BoxRef = useRef(null);

  const [editing, setEditing] = useState(image == null);
  const [oldImage, setOldImage] = useState(image);

  const canvasWidth = useMemo(() => {
    let clientWidth = BoxRef?.current?.clientWidth;
    return clientWidth || 940;
  }, [BoxRef.current?.clientWidth]);

  const clearSignature = () => {
    signatureCanvas.current.clear();
    saveSignature(false);
  };

  const saveSignature = () => {
    let signature = null;

    if (!signatureCanvas.current.isEmpty()) {
      signature = signatureCanvas.current.getTrimmedCanvas().toDataURL('image.png');
    }

    if (signature) {
      if (onChange) onChange(signature);
      setEditing(false);
    }
  };

  const cancelSignature = () => {
    setEditing(false);
    if (onChange) onChange(oldImage);
  };

  return (
    <Box>
      <Box className={classes.buttonContainer}>
        {editing ? (
          <>
            {oldImage && (
              <Button
                size="small"
                variant="contained"
                onClick={cancelSignature}
                className={classes.clearBtn}>
                Cancel
              </Button>
            )}

            <Button
              size="small"
              variant="contained"
              onClick={clearSignature}
              className={classes.clearBtn}>
              Clear
            </Button>

            <Button
              size="small"
              variant="contained"
              onClick={saveSignature}
              className={classes.clearBtn}>
              Save
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => setEditing(true)}>Change Signature</Button>
          </>
        )}
      </Box>

      <Box ref={BoxRef} className={classes.container}>
        {editing ? (
          <SignatureCanvas
            ref={signatureCanvas}
            backgroundColor="white"
            velocityFilterWeight={1}
            minWidth={1}
            maxWidth={2}
            penColor="black"
            canvasProps={{
              display: 'flex',
              width: canvasWidth,
              height: 250,
              border: '1px dotted red',
              overflow: 'hidden',
            }}
          />
        ) : (
          <img className={classes.image} src={image} />
        )}
      </Box>
    </Box>
  );
};

export default Signature;
