import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { InputBase } from '@mui/material';

export const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    border: '2px solid rgb(235, 235, 235)',
    backgroundColor: theme.palette.custom.lightGray,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIconWrapper: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100% !important',

  '& .MuiInputBase-input': {
    padding: '9px',
    borderRadius: '10px',
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100% !important',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
    },
  },
}));
