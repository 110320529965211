import React from 'react';
import { Box } from '@mui/material';
import ProfileCard from 'components/profile/ProfileCard';

const MyProfile = () => {
  return (
    <Box>
      <ProfileCard />
    </Box>
  );
};

export default MyProfile;
