import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    marginRight: '10px !important',
    background: '#f5f8fa !important',
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '20px',
    alignItems: 'center',
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  header: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 20px',
    cursor: 'pointer',
  },
  title: {
    color: 'red !important',
  },
  overflow: { overflowY: 'hidden!important' },
}));
