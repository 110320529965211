import React, { forwardRef, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Grow,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import FormattedInputs from 'components/NumberFormat';
import { CUSTOMERS_API } from 'api/api_routes';
import api from 'api';
import { useDispatch } from 'react-redux';
import { createCustomer, updateCustomer } from 'store/common/customers/action';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const CustomerPopup = ({ handleClose, customerState, mode, onSuccess }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState(null);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    full_name: '',
    address: '',
    phone_number: '',
    alt_phone_number: '',
    email: '',
    dl_number: '',
    dl_expire_date: '',
    ...customerState,
  });

  const setStateValue = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const sendForm = async (id) => {
    if (mode == 'add') {
      const response = await api.post(CUSTOMERS_API, state, { onErrors: setErrors });
      if (response.status) {
        dispatch(createCustomer(response.customer));
        onSuccess && onSuccess();
        handleClose();
      }
    } else {
      const response = await api.put(`${CUSTOMERS_API}/${state.id}`, state, {
        onErrors: setErrors,
      });
      if (response.status) {
        dispatch(updateCustomer(response.customer));
        onSuccess && onSuccess();

        handleClose();
      }
    }

    // if (onConfirm) onConfirm(state?.id);
  };

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">{mode == 'add' ? 'Add new customer' : 'Edit customer'}</Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent className={classes.content}>
        <Box className={classes.container} noValidate autoComplete="off">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  color="secondary"
                  label="Full Name"
                  required
                  value={state?.full_name}
                  onChange={(e) => setStateValue('full_name', e.target.value)}
                  className={classes.marginBottom}
                />
                {errors && <Typography variant="errors">{errors?.full_name}</Typography>}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  color="secondary"
                  label="Home Address"
                  required
                  value={state?.address}
                  onChange={(e) => setStateValue('address', e.target.value)}
                  className={classes.marginBottom}
                />
                {errors && <Typography variant="errors">{errors?.address}</Typography>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  label="Email"
                  required
                  color="secondary"
                  value={state?.email}
                  onChange={(e) => setStateValue('email', e.target.value)}
                  className={classes.marginBottom}
                />
                {errors && <Typography variant="errors">{errors?.email}</Typography>}
              </Grid>

              <Grid item xs={3}>
                <FormattedInputs
                  value={state?.phone_number}
                  required
                  label="Phone Number"
                  onChange={(value) => setStateValue('phone_number', value)}
                />
                {errors && <Typography variant="errors">{errors?.phone_number}</Typography>}
              </Grid>

              <Grid item xs={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  color="secondary"
                  label="DL#"
                  className={classes.marginBottom}
                  value={state?.dl_number}
                  onChange={(e) => setStateValue('dl_number', e.target.value)}
                />
                {errors && <Typography variant="errors">{errors?.dl_number}</Typography>}
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              className={classes.cancel}
              onClick={handleClose}>
              <Typography variant="subtitle3">Cancel</Typography>
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={sendForm}>
              <Typography variant="subtitle4">{mode == 'add' ? 'Create' : 'Save'}</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerPopup;
