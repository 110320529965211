import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  eventsResult: {
    position: 'fixed',
    right: '20px',
    width: 'auto',
    maxWidth: '300px',
    height: 'auto',
    zIndex: '1000',
    [theme.breakpoints.down(1650)]: {
      display: 'block',
      position: 'static',
      width: '100%',
      maxWidth: 'none',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '75%',
    rowGap: '20px',
    [theme.breakpoints.down(1650)]: {
      gridTemplateColumns: '100%',
    },
  },
  card: {
    padding: '30px',
  },
  title: {
    paddingBottom: '30px',
  },
}));
