import api from 'api';
import { EVENTS_API } from 'api/api_routes';
import { handleUser } from '../auth/action';
import { createEvent, deleteEvent, getEvent, loadEvent, updateEvent } from './action';

export const createEventThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadEvent());

    const response = await api.post(EVENTS_API, data, { onErrors });

    // if (response.status) {
    //   // dispatch(createEvent(response.event));
    // }
  };

export const getEventThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadEvent());

    const response = await api.get(EVENTS_API, data, { onErrors });

    if (response.status) {
      dispatch(getEvent(response));
    }
  };

export const deleteEventThunk =
  (id, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadEvent());

    const response = await api.delete(`${EVENTS_API}/${id}`, { onErrors });

    if (response.status) {
      dispatch(deleteEvent(id));
    }
  };

export const updateEventThunk =
  ({ data, id, onErrors = null }) =>
  async (dispatch) => {
    dispatch(loadEvent());
    const response = await api.put(`${EVENTS_API}/${id}`, data, { onErrors });

    if (response.status) {
      dispatch(updateEvent(response.events));

      if (response.events?.id == 1) {
        dispatch(handleUser(response.event));
      }
    }
  };
