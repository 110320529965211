import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: { padding: '25px' },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(3),
  },

  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '10px',
    fontSize: '14px',
    position: 'relative',
  },
  button: {
    height: '40px',
    width: '100%',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
}));
