export const initialState = {
  auth: {
    isLoad: false,
    user: null,
    lists: {},
    settings: null,
  },
  staff: {
    data: null,
    users: null,
    isLoad: false,
  },
  appointments: {
    data: null,
    appointments: null,
    isLoad: false,
  },
  customers: {
    data: null,
    customers: null,
    isLoad: false,
  },
  settings: {
    data: null,
    event_menus: null,
    isLoad: false,
  },
  events: {
    data: null,
    events: null,
    isLoad: false,
  },
  changes: {
    changes: null,
    isLoad: false,
  },
};
