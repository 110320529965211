import React, { forwardRef } from 'react';
import {
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grow,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import { formatNumber } from 'utils/formatNumber';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const DetailsDialog = ({ isOpen, handleClose, changes }) => {
  const classes = useStyles();

  const changeFields = {
    amount: 'Amount',
    guests_number: 'Minimum guest number',
    guests_number_expected: 'Final number of guests',
    cost_per_guest: 'Cost per guest',
    discount: 'Discount',
    service_fee: 'Service fee',
    services: 'Services',
    payments: 'Payment',
    payment: 'Payment',
    ceremony: 'Ceremony onsite',
    cocktail_hour: 'Cocktail Hour',
    gratitude: 'Gratuity',
    lightning: 'Chair & Table Removal',
    other: 'Other',
    security: 'Bar & Bartenders',
    tax: 'Tax',
    valet_parking: 'Valet Parking',
    payment_type: 'Payment type',
    payment_name: 'Payment name',
    deposit: 'Deposit',

    security_new: 'Security',
    lightning_new: 'Special Events Lighting',
    led_screen_new: 'LED Screen',
  };

  function formatField(field, value) {
    if (['guests_number', 'guests_number_expected'].indexOf(field) > -1) {
      return parseInt(value);
    }

    return formatNumber(Number(value));
  }

  function createChangeRow(field, change) {
    return Object.keys(changeFields).indexOf(field) > -1 ? (
      <TableRow key={field} hover>
        <TableCell>{changeFields[field]}</TableCell>
        <TableCell style={styles.beforeColumn}>
          {(change.old && change.old == Number(change.old)
            ? formatField(field, change.old)
            : change.old) || 'N/A'}
        </TableCell>
        <TableCell style={styles.afterColumn}>
          {(change.new && change.new == Number(change.new)
            ? formatField(field, change.new)
            : change.new) || 'N/A'}
        </TableCell>
      </TableRow>
    ) : (
      <tr key={field}></tr>
    );
  }

  function getChanges(changes) {
    return Object.entries(changes).map(([field, value]) => {
      if (field == 'payments') {
        if (Array.isArray(value)) {
          return value.map((payment, index) => {
            if (payment['action']) {
              let values = Object.keys(payment.new).map((item) => payment.new[item]);

              let name = payment?.new?.name || null;

              return (
                <TableRow key={index}>
                  <TableCell>{changeFields[field]}</TableCell>
                  <TableCell style={styles.beforeColumn}>
                    {payment.action == 'sub' ? 'Deleted payment' : 'Added payment'} {'  '}({name})
                  </TableCell>
                  <TableCell style={styles.afterColumn}>
                    {values.map((value, index) => (
                      <>
                        {name != value ? (
                          <div key={index}>{(value && formatNumber(Number(value))) || 'N/A'}</div>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                  </TableCell>
                </TableRow>
              );
            } else {
              if (typeof payment?.new == 'object' || typeof payment?.old == 'object') {
                return (
                  <TableRow key={`${field}_${index}`}>
                    <TableCell>
                      {changeFields[field]} ({payment.old?.name})
                    </TableCell>
                    <TableCell style={styles.beforeColumn}>
                      {payment.old?.value && formatField(field, payment.old?.value)}
                    </TableCell>
                    <TableCell style={styles.afterColumn}>
                      {payment.new?.value && formatField(field, payment.new?.value)}
                    </TableCell>
                  </TableRow>
                );
              } else {
                return (
                  <TableRow key={`${field}_${index}`}>
                    <TableCell>{changeFields[field]}</TableCell>
                    <TableCell style={styles.beforeColumn}>
                      {payment.old && formatField(field, payment.old)}
                    </TableCell>
                    <TableCell style={styles.afterColumn}>
                      {payment.new && formatField(field, payment.new)}
                    </TableCell>
                  </TableRow>
                );
              }
            }
          });
        } else {
          return Object.keys(value).map((field) => {
            return createChangeRow(field, value[field]);
          });
        }
      } else {
        if (value['old'] || value['new']) {
          return createChangeRow(field, value);
        } else if (Array.isArray(value) || ['contract', 'service_list'].indexOf(field) > -1) {
          return getChanges(value);
        }
      }
    });
  }

  const styles = {
    fontStyle: {
      color: '#333333',
      marginBottom: '5px',
      fontFamily: 'Poppins',
      fontWeight: '500',
      fontSize: '16px',
    },

    beforeColumn: {
      borderLeft: '1px solid rgba(224, 224, 224, 0.7)',
    },

    afterColumn: {
      backgroundColor: 'rgb(230, 241, 253)',
      borderLeft: '1px solid rgba(224, 224, 224, 0.7)',
    },
  };

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">Change Details</Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />
      <DialogContent sx={{ padding: '0px' }}>
        <Box noValidate autoComplete="off">
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.fontStyle}>Field</TableCell>
                  <TableCell style={{ ...styles.fontStyle, ...styles.beforeColumn }}>
                    Before
                  </TableCell>
                  <TableCell style={{ ...styles.fontStyle, ...styles.afterColumn }}>
                    After
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{getChanges(changes.attributes)}</TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DetailsDialog;
