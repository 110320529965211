import React, { forwardRef, useMemo, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Grow,
  InputLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import FormattedInputs from 'components/NumberFormat';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'store/common/auth/selector';
import Signature from 'pages/event/Events/Signature';
import { STAFF_API } from 'api/api_routes';
import api from 'api';
import { handleUser } from 'store/common/auth/action';
import { createStaff, updateStaff } from 'store/common/staff/action';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const UserPopup = ({ handleClose, userState, onConfirm, mode, signature, onSuccess }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState(null);
  const { lists } = useSelector(selectUser);

  const { user } = useSelector(selectUser);

  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    address: '',
    birth_date: '',
    password_confirmation: '',
    role_id: '1',
    position_id: '1',
    ...userState,
  });

  const dispatch = useDispatch();

  const setStateValue = (field, value) => {
    let data = {
      [field]: value,
    };

    if (field == 'position_id') {
      // waiter
      if (['3', '4', '5'].indexOf(value) > -1) {
        let password = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (var i = 0; i < 8; i++) {
          password += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        data['password'] = password;
        data['password_confirmation'] = password;
      } else {
        data['password'] = '';
        data['password_confirmation'] = '';
      }
    }

    setState({ ...state, ...data });
  };

  const sendForm = async () => {
    if (mode == 'add') {
      const response = await api.post(STAFF_API, state, { onErrors: setErrors });
      if (response.status) {
        dispatch(createStaff(response.user));

        onSuccess && onSuccess();
        handleClose();
      }
    } else {
      const response = await api.put(`${STAFF_API}/${state.id}`, state, { onErrors: setErrors });
      if (response.status) {
        if (state.id == user.id) {
          dispatch(handleUser(response.user));
        }
        dispatch(updateStaff(response.user));

        onSuccess && onSuccess();
        handleClose();
      }
    }
    if (onConfirm) {
      onConfirm(userState?.id);
    }
  };

  const isSaveDisabled = useMemo(() => {
    switch (mode) {
      case 'add':
        return false;

      case 'edit': {
        return (
          user.id == state.id && ['1', '2'].indexOf(user.role_id) > -1 && state.signature == null
        );
      }
    }

    return true;
  }, [state]);

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">
          {mode == 'add' ? 'Add new staff member' : 'Edit staff member'}
        </Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent className={classes.content}>
        <Box className={classes.container} noValidate autoComplete="off">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="First Name"
                  required
                  color="secondary"
                  value={state?.first_name}
                  onChange={(e) => setStateValue('first_name', e.target.value)}
                  className={classes.marginBottom}
                />
                {errors && <Typography variant="errors">{errors?.first_name}</Typography>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  required
                  color="secondary"
                  label="Last Name"
                  value={state?.last_name}
                  onChange={(e) => setStateValue('last_name', e.target.value)}
                  className={classes.marginBottom}
                />
                {errors && <Typography variant="errors">{errors?.last_name}</Typography>}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  label="Email"
                  color="secondary"
                  required
                  value={state?.email}
                  onChange={(e) => setStateValue('email', e.target.value)}
                  className={classes.marginBottom}
                />
                {errors && <Typography variant="errors">{errors?.email}</Typography>}
              </Grid>
              <Grid item xs={6}>
                <FormattedInputs
                  value={state?.phone_number}
                  required
                  label="Phone"
                  onChange={(value) => setStateValue('phone_number', value)}
                />
                {errors && <Typography variant="errors">{errors?.phone_number}</Typography>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Address"
                  color="secondary"
                  value={state?.address}
                  onChange={(e) => setStateValue('address', e.target.value)}
                  className={classes.marginBottom}
                />
                {/* {errors && <Typography variant="errors">{errors?.address}</Typography>} */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label="Birth Date"
                  color="secondary"
                  value={state?.birth_date}
                  onChange={(e) => setStateValue('birth_date', e.target.value)}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9-./\\]/g, '');
                  }}
                  className={classes.marginBottom}
                />
                {/* {errors && <Typography variant="errors">{errors?.address}</Typography>} */}
              </Grid>
              {['1', '2'].indexOf(user.role_id) > -1 && (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="position-select-label" color="secondary">
                        Position
                      </InputLabel>
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        labelId="position-select-label"
                        value={state?.position_id || ''}
                        label="Position"
                        color="secondary"
                        onChange={(e) => setStateValue('position_id', e.target.value)}
                        style={{ textTransform: 'capitalize' }}>
                        {Object.entries(lists?.job_positions || {}).map(([key, value]) => {
                          return (
                            <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                              {value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {errors && <Typography variant="errors">{errors?.position_id}</Typography>}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="role-select-label" color="secondary">
                        Role
                      </InputLabel>
                      <Select
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                        labelId="role-select-label"
                        label="Role"
                        color="secondary"
                        value={state?.role_id || ''}
                        onChange={(e) => setStateValue('role_id', e.target.value)}
                        style={{ textTransform: 'capitalize' }}>
                        {Object.entries(lists?.user_roles || {}).map(([key, value]) => {
                          return (
                            <MenuItem key={key} value={key} style={{ textTransform: 'capitalize' }}>
                              {value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {errors && <Typography variant="errors">{errors?.role_id}</Typography>}
                  </Grid>
                </>
              )}
              {mode == 'add' && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Password"
                      required
                      color="secondary"
                      variant="outlined"
                      type="password"
                      className={classes.marginBottom}
                      value={state?.password}
                      onChange={(e) => setStateValue('password', e.target.value)}
                    />
                    {errors && <Typography variant="errors">{errors?.password}</Typography>}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="password"
                      color="secondary"
                      label=" Confirm Password"
                      required
                      className={classes.marginBottom}
                      value={state?.password_confirmation}
                      onChange={(e) => setStateValue('password_confirmation', e.target.value)}
                    />
                    {errors && (
                      <Typography variant="errors">{errors?.password_confirmation}</Typography>
                    )}
                  </Grid>
                </>
              )}
              {signature && ['1', '2'].indexOf(state.role_id) > -1 && (
                <Grid item xs={12}>
                  Signature*
                  <Signature
                    image={state.signature}
                    clearable={['1', '2'].indexOf(state.role_id) > -1}
                    onChange={(encodedImage) => {
                      setStateValue('signature', encodedImage);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          <Box className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              className={classes.cancel}
              onClick={handleClose}>
              <Typography variant="subtitle3">Cancel</Typography>
            </Button>
            <Button
              disabled={isSaveDisabled}
              size="small"
              variant="contained"
              color="primary"
              onClick={sendForm}>
              <Typography variant="subtitle4">{mode == 'add' ? 'Create' : 'Save'}</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UserPopup;
