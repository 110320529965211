import React, { useState } from 'react';
import UserPopup from 'pages/Staff/Dialog/UserPopup';
import { Alert, Snackbar } from '@mui/material';

const EditProfile = ({ isOpen, handleClose, state, userId }) => {
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <>
      {isOpen && (
        <UserPopup
          mode="edit"
          signature
          userState={{ ...state, id: userId }}
          onConfirm={handleClose}
          handleClose={handleClose}
          onSuccess={() => setShowSuccess(true)}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowSuccess(false)}
        open={showSuccess}
        key={'bottom' + 'center'}>
        <Alert severity="success" variant="filled">
          Your changes were saved successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProfile;
