import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  eventsResult: {
    position: 'fixed',
    right: '20px',
    width: 'auto',
    maxWidth: '300px',
    height: 'auto',
    zIndex: '1000',
  },
  margin: {
    margin: '5px 20px',
  },
  radioGroup: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
  content: {
    padding: '20px 0',
  },
  paddingLeft: {
    paddingLeft: '10px',
  },
  editBtn: {
    width: '100%',
  },
}));
