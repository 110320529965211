import React, { useState } from 'react';
import { Alert, ButtonGroup, IconButton, Snackbar, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SettingDialog from '../Dialog';
import ResponsiveDialog from 'components/ResponsiveDialog';
import { deleteMenuThunk } from 'store/common/settings/thunk';
import { useDispatch } from 'react-redux';

const Actions = ({ menuState }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [showSuccess, setShowSuccess] = useState(false);

  const [dialogs, setDialogs] = useState({
    edit: false,
    delete: false,
  });

  const handleDialogState = (dialogState) => {
    setDialogs({ ...dialogs, ...dialogState });
    setAnchorEl(null);
  };
  const DeleteMenuAction = (menuId) => {
    handleDialogState({ delete: false });
    dispatch(deleteMenuThunk(menuId));
  };

  return (
    <>
      <ButtonGroup>
        <Tooltip title="Edit">
          <IconButton onClick={() => handleDialogState({ edit: true })}>
            <EditIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton onClick={() => handleDialogState({ delete: true })}>
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </ButtonGroup>

      {dialogs.edit && (
        <SettingDialog
          mode="edit"
          handleClose={() => handleDialogState({ edit: false })}
          onConfirm={() => handleDialogState({ edit: false })}
          menuState={menuState}
          onSuccess={() => setShowSuccess(true)}
        />
      )}

      {dialogs.delete && (
        <ResponsiveDialog
          label="Are you sure you want to delete this menu?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={DeleteMenuAction}
          dataId={menuState.id}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowSuccess(false)}
        open={showSuccess}
        key={'bottom' + 'center' + 'success'}>
        <Alert severity="success" variant="filled">
          Your changes were saved successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Actions;
