import { Box } from '@mui/material';
import React from 'react';
import SettingsGrid from '../../components/settings/Settings';

const Settings = () => {
  return (
    <Box>
      <SettingsGrid />
    </Box>
  );
};

export default Settings;
