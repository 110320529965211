import React, { useState } from 'react';
import SettingDialog from './Dialog';
import { useStyles } from './styles';
import Box from '@mui/material/Box';
import { Alert, Button, Card, Divider, Snackbar, Typography } from '@mui/material';
import { AfterEventSms, AppointmentSms, BeforeEventSms, Menu } from '..';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/BasicTabs/TabPanel';

const SettingsGrid = () => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const [dialogState, setDialogState] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const setTabValue = (idx) => {
    setCurrentTab(idx);
  };

  return (
    <>
      <Card className={classes.card}>
        <Box className={classes.header}>
          <Typography className={classes.title} variant="subtitle1">
            Settings
          </Typography>
          {currentTab == 0 && (
            <Box className={classes.btnContainer}>
              <Button
                variant="contained"
                className={classes.button}
                onClick={() => setDialogState(true)}>
                <Typography variant="h5" className={classes.textAttribute}>
                  Create Menu
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        <Divider />
        <Box>
          {dialogState && (
            <SettingDialog
              mode="add"
              handleClose={() => setDialogState(false)}
              onSuccess={() => setShowSuccess(true)}
            />
          )}

          {/* <BasicTabs {...settingsTabsData} /> */}
        </Box>

        <Tabs
          value={currentTab}
          onChange={(e, idx) => setTabValue(idx)}
          aria-label="secondary tabs example"
          variant={'scrollable'}
          // variant="fullWidth"
          scrollButtons={'auto'}
          textColor="secondary"
          indicatorColor="secondary"
          sx={{
            flexShrink: 0,
            overflowX: 'auto !important',
            overflowY: 'hidden !important',
          }}>
          <Tab variant="text" label="Menu" />
          <Tab variant="text" label="Before Event Sms" />
          <Tab variant="text" label="After Event Sms" />
          <Tab variant="text" label="Appointment Sms" />
        </Tabs>
        <Divider />

        <Box>
          <TabPanel value={currentTab} index={0}>
            <Menu />
          </TabPanel>

          <TabPanel value={currentTab} index={1}>
            <BeforeEventSms onSuccess={() => setShowSuccess(true)} />
          </TabPanel>

          <TabPanel value={currentTab} index={2}>
            <AfterEventSms onSuccess={() => setShowSuccess(true)} />
          </TabPanel>

          <TabPanel value={currentTab} index={3}>
            <AppointmentSms onSuccess={() => setShowSuccess(true)} />
          </TabPanel>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={() => setShowSuccess(false)}
          open={showSuccess}
          key={'bottom' + 'center' + 'success'}>
          <Alert severity="success" variant="filled">
            Your changes were saved successfully!
          </Alert>
        </Snackbar>
      </Card>
    </>
  );
};

export default SettingsGrid;
