import React, { useEffect, useState } from 'react';
import api from 'api';
import { CALENDAR_API } from 'api/api_routes';
import BasicCalendar from 'components/BasicCalendar';
import { Box, Card, FormControl, InputLabel, Select } from '@mui/material';
import { useStyles } from './styles';
import moment from 'moment';

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [dateState, setDateState] = useState(new Date());
  const classes = useStyles();

  const years = Array.from({ length: 21 }, (_, i) => dateState.getFullYear() - 10 + i);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    // fetchEvents();
  }, []);

  useEffect(() => {
    const range = {
      start: moment(dateState).startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
      end: moment(dateState).endOf('month').add(1, 'month').format('YYYY-MM-DD'),
    };

    fetchEvents(range);
  }, [dateState]);

  const fetchEvents = async (range = {}) => {
    const response = await api.post(CALENDAR_API, { range });
    if (response.status) {
      setEvents(response.events);
    }
  };

  return (
    <Card className={classes.wrapper} sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ mx: 'auto', my: '15px !important' }}>
        <FormControl>
          <InputLabel color="secondary">Year</InputLabel>
          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            native
            size="small"
            value={dateState.getFullYear()}
            sx={{ width: '140px' }}
            label="Year"
            onChange={(e) => setDateState(new Date(e.target.value, dateState.getMonth()))}>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ ml: 1 }}>
          <InputLabel color="secondary">Month</InputLabel>

          <Select
            MenuProps={{
              disableScrollLock: true,
            }}
            native
            size="small"
            value={dateState.getMonth()}
            sx={{ width: '140px' }}
            label="Month"
            onChange={(e) => setDateState(new Date(dateState.getFullYear(), e.target.value))}>
            {months.map((month, index) => (
              <option key={month} value={index}>
                {month}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>

      <BasicCalendar
        events={events}
        onRangeChange={fetchEvents}
        dateState={dateState}
        setDateState={setDateState}
      />
    </Card>
  );
};

export default Calendar;
