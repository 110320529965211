import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  margin: {
    margin: '20px 0 20px',
  },
  addPaymentBtn: {
    margin: '20px 0 20px',
    float: 'right',
  },

  menuSelect: { width: '335px' },
  btnWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  whiteSpace: {
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'nowrap',
    },
  },
  fontSize: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
}));
