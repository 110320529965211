import { Pagination } from '@mui/material';
import { useStyles } from './styles';
import React from 'react';

const BasicPagination = ({ count, page, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Pagination
        className={classes.paginator}
        count={count || 0}
        page={page || 1}
        onChange={onChange}
      />
    </div>
  );
};

export default BasicPagination;
