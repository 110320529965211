import { Box } from '@mui/system';
import ResetPasswordForm from '../../../components/forms/ResetPasswordForm';
import { useStyles } from './styles';

const ResetPassword = () => {
  const classes = useStyles();

  return (
    <Box data-tid="reset-password-page" className={classes.container}>
      <Box className={classes.leftContent}></Box>
      <Box className={classes.rightContent}>
        <ResetPasswordForm />
      </Box>
    </Box>
  );
};

export default ResetPassword;
