import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useStyles } from './styles';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link } from 'react-router-dom';
import { formatNumber } from 'utils/formatNumber';

const EventsResult = ({ state, setState, onConfirm }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const [dialogs, setDialogs] = useState({
    edit: false,
    delete: false,
  });

  const handleDialogState = (dialogState) => {
    setDialogs({ ...dialogs, ...dialogState });
    setAnchorEl(null);
  };

  useEffect(() => {
    grandTotal();
  }, []);

  const setStateValues = (field, value) => {
    setState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const grandTotal = (format = true) => {
    let grandTotal = 0;
    let costPerGuest = 0;

    let services = Object.keys(state?.contract?.service_list)
      .filter((key) => ['tax', 'gratitude', 'valet_parking'].indexOf(key) == -1)
      .map((key) => state?.contract?.service_list[key]);

    let serviceList = services.reduce((acc, value) => {
      return acc + +value;
    }, 0);

    serviceList -= Number(state.contract.discount);

    if (state.type_id == 4) {
      costPerGuest = +state?.contract?.cost_per_guest * state.guests_number;
    } else {
      costPerGuest =
        +state?.contract?.cost_per_guest * (state.guests_number_expected || state.guests_number);
    }

    grandTotal = serviceList + Number(costPerGuest);

    if (state.type_id != 4) {
      grandTotal += (grandTotal * Number(state.contract.service_fee)) / 100;
    }

    if (state.contract?.service_list.tax) {
      grandTotal += (grandTotal * Number(state.contract?.service_list.tax)) / 100;
    }

    grandTotal +=
      Number(state.contract?.service_list.gratitude) +
      Number(state.contract?.service_list.valet_parking);

    let num = parseFloat(grandTotal);
    return format ? formatNumber(num) : num;
  };

  const payments = state?.payments
    .filter((_, i) => {
      return i !== 0;
    })
    .reduce((acc, value) => {
      return acc + +value?.amount;
    }, 0);

  const balanceDue = () => {
    let gt = grandTotal(false);

    let result =
      state.payments?.length &&
      parseFloat(gt - (Number(state.payments[0].amount) + Number(payments)));

    state.contract.grand_total = gt;
    state.contract.balance_due = result;

    return formatNumber(result);
  };

  return (
    <Box className={classes.margin}>
      <Grid container sx={{ margin: '17px 0' }}>
        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            className={classes.radioGroup}
            value={state.status_id}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            sx={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={6} md={3} lg={6}>
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  label="Booked"
                  value={1}
                  onChange={(e) => setStateValues('status_id', e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={6}>
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  label="Completed"
                  value={3}
                  onChange={(e) => setStateValues('status_id', e.target.value)}
                />
              </Grid>

              <Grid item xs={6} md={3} lg={6}>
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  label="Pending"
                  value={2}
                  onChange={(e) => setStateValues('status_id', e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={6}>
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  label="Canceled"
                  value={4}
                  onChange={(e) => setStateValues('status_id', e.target.value)}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>

        <Grid container className={classes.content}>
          <Grid item xs={6}>
            <Typography gutterBottom variant="subtitle3" component="div">
              Deposit
            </Typography>

            <Typography gutterBottom variant="subtitle3" component="div">
              Payment
            </Typography>

            <Typography gutterBottom variant="subtitle3" component="div">
              Balance due
            </Typography>

            <Typography gutterBottom variant="subtitle3" component="div">
              Grand total
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={classes.paddingLeft}
              gutterBottom
              variant="subtitle3"
              component="div">
              $
              {(state.payments[0]?.payment_name === 'deposit' &&
                formatNumber(Number(state.payments[0]?.amount || 0))) ||
                0}
            </Typography>
            <Typography
              className={classes.paddingLeft}
              gutterBottom
              variant="subtitle3"
              component="div">
              ${formatNumber(payments)}
            </Typography>
            <Typography
              className={classes.paddingLeft}
              gutterBottom
              variant="subtitle3"
              component="div">
              ${balanceDue()}
            </Typography>
            <Typography
              className={classes.paddingLeft}
              gutterBottom
              variant="subtitle3"
              component="div">
              ${grandTotal()}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={{ xs: 3 }}>
          <Grid item xs={2} sm={1} md={3}>
            <Tooltip title="Export">
              <a target="_blank" rel="noreferrer" href={state?.agreementURI}>
                <IconButton aria-label="Export" color="pdf">
                  <PictureAsPdfIcon />
                </IconButton>
              </a>
            </Tooltip>
          </Grid>

          <Grid item xs={10} sm={11} md={9}>
            <Button
              size="small"
              variant="contained"
              className={classes.editBtn}
              onClick={onConfirm}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventsResult;
