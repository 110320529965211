import api from 'api';
import { CUSTOMERS_API } from 'api/api_routes';
import { handleUser } from '../auth/action';
import {
  createCustomer,
  deleteCustomer,
  getCustomer,
  loadCustomer,
  updateCustomer,
} from './action';

export const createCustomerThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadCustomer());

    const response = await api.post(CUSTOMERS_API, data, { onErrors });
  };

export const getCustomerThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadCustomer());

    const response = await api.get(`${CUSTOMERS_API}`, data, { onErrors });

    if (response.status) {
      dispatch(getCustomer(response));
    }
  };

export const deleteCustomerThunk =
  (id, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadCustomer());

    const response = await api.delete(`${CUSTOMERS_API}/${id}`, { onErrors });

    if (response.status) {
      dispatch(deleteCustomer(id));
    }
  };

export const updateCustomerThunk =
  ({ data, id, onErrors = null }) =>
  async (dispatch) => {
    dispatch(loadCustomer());
    const response = await api.put(`${CUSTOMERS_API}/${id}`, data, { onErrors });

    if (response.status) {
      dispatch(updateCustomer(response.customers));

      if (response.customers?.id == 1) {
        dispatch(handleUser(response.customer));
      }
    }
  };
