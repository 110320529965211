import React, { useState } from 'react';
import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { useStyles } from '../styles';
import logo from '../../../assets/images/logo.jpg';
import { authPaths } from '../../../constants/auth';

import { Link } from 'react-router-dom';
import { initThunk, loginThunk } from 'store/common/auth/thunk';
import { useDispatch } from 'react-redux';

const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [serverErrors, setServerErrors] = useState(null);

  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const setStateValue = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const onSubmit = async () => {
    setServerErrors(null);
    await dispatch(loginThunk(state, setServerErrors));
    await dispatch(initThunk());
  };

  return (
    <>
      <Box data-tid="login-form" className={classes.wrapper}>
        <img className={classes.logo} alt="logo" src={logo} />
        <Card className={classes.form}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="p" className={classes.textAttribute}>
                Sign In to Grand Venue
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {serverErrors && <Typography variant="errors">{serverErrors?.message}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Email"
                required
                type="text"
                color="secondary"
                className={classes.marginBottom}
                id="filled-size-small"
                value={state.username}
                onChange={(e) => setStateValue('username', e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                label="Password"
                required
                color="secondary"
                className={classes.marginBottom}
                id="filled-size-small"
                value={state.password}
                onChange={(e) => setStateValue('password', e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.textAttribute} size="small">
                <Link className={classes.linkedText} to={authPaths.resetPassword}>
                  Forgot Password ?
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                onClick={onSubmit}
                className={classes.continueBtn}>
                <Typography variant="h5">Continue</Typography>
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default LoginForm;
