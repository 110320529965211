import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Card, Divider, Typography } from '@mui/material';
import EditProfile from '../Dialog/EditProfile';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/common/auth/selector';

const ProfileCard = () => {
  const classes = useStyles();
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenResetDialog, setIsOpenResetDialog] = useState(false);
  const { user } = useSelector(selectUser);

  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    role_id: '1',
    position_id: '1',
    address: '',
    birth_date: '',
    password: '',
    password_confirmation: '',
    signature: '',
  });

  const handleResetDialogOpen = () => {
    setIsOpenResetDialog(true);
  };

  const handleEditDialogOpen = () => {
    setIsOpenEditDialog(true);
  };

  const handleEditDialogClose = () => {
    setIsOpenEditDialog(false);
  };

  const handleResetDialogClose = () => {
    setIsOpenResetDialog(false);
  };

  const onSubmit = () => {};

  useEffect(() => {
    if (user) {
      setState({
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone_number: user?.phone_number,
        role_id: user?.role_id,
        position_id: user?.position_id,
        address: user?.address,
        birth_date: user?.birth_date,
        signature: user?.signature,
      });
    }
  }, [user]);

  return (
    <>
      <Card
        className={classes.card}
        sx={{ overflowX: 'auto !important', overflowY: 'hidden !important' }}>
        <Grid className={classes.header}>
          <Typography className={classes.title} variant="subtitle1">
            Profile Details
          </Typography>
          <Grid className={classes.btnContainer}>
            <Button variant="contained" className={classes.button} onClick={handleEditDialogOpen}>
              <Typography className={classes.textAttribute} variant="h5">
                Edit Profile
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Divider />

        <Grid container spacing={{ xs: 2, md: 3 }} className={classes.gridContainer}>
          <Grid item xs={6}>
            <div>
              <Typography variant="subtitle3" className={classes.content}>
                First Name
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle3" className={classes.content}>
                Last Name
              </Typography>
            </div>
            <Grid item xs={6}>
              <Typography variant="subtitle3" className={classes.content}>
                Email
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle3" className={classes.content}>
                Phone
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle3" className={classes.content}>
                Position
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle3" className={classes.content}>
                Role
              </Typography>
            </Grid>
            {['1', '2'].indexOf(state.role_id) > -1 && (
              <Grid item xs={12}>
                <Typography variant="subtitle3" className={classes.content}>
                  Signature
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6} key={user?.id}>
            <Grid item xs={6}>
              <Typography className={classes.content}> {user?.first_name} </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.content}> {user?.last_name} </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.content}> {user?.email} </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className={classes.content}> {user?.formatted_phone_number} </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.content}> {user?.position.name} </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography className={classes.content}> {user?.role.name} </Typography>
            </Grid>

            {['1', '2'].indexOf(state.role_id) > -1 && (
              <Grid item xs={6}>
                <Typography className={classes.content}>
                  {user?.signature ? (
                    <img
                      className={classes.image}
                      src={user?.signature}
                      width="90px"
                      height="100%"
                      alt="signature"
                    />
                  ) : (
                    <Typography sx={{ color: 'red' }}>Please add your signature</Typography>
                  )}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
      <EditProfile
        isOpen={isOpenEditDialog}
        handleClose={handleEditDialogClose}
        onSubmit={onSubmit}
        state={state}
        setState={setState}
        userId={user?.id}
        userSignature={user?.signature}
      />
    </>
  );
};

export default ProfileCard;
