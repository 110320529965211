import { pagesPaths } from './pagesPaths';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventIcon from '@mui/icons-material/Event';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import BarChartIcon from '@mui/icons-material/BarChart';

export const sidebarList = [
  {
    name: 'calendar',
    link: pagesPaths.calendar,
    icon: <CalendarMonthIcon />,
    roles: ['administrator', 'manager', 'user'],
  },
  {
    name: 'appointments',
    link: pagesPaths.appointments,
    icon: <EventAvailableIcon />,
    roles: ['administrator', 'manager', 'user'],
  },
  {
    name: 'events',
    link: pagesPaths.events,
    icon: <EventIcon />,
    roles: ['administrator', 'manager', 'user'],
  },
  {
    name: 'staff',
    link: pagesPaths.staff,
    icon: <AccountCircleIcon />,
    roles: ['administrator', 'manager', 'user'],
  },
  {
    name: 'customers',
    link: pagesPaths.customers,
    icon: <GroupIcon />,
    roles: ['administrator', 'manager', 'user'],
  },
  {
    name: 'changes',
    link: pagesPaths.changes,
    icon: <BarChartIcon />,
    roles: ['administrator', 'manager'],
  },
];
