import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ListItemButton } from '@mui/material';
import { useStyles } from './styles';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';

const LinkedItem = ({ name, link, icon, handleDrawerToggle }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isActiveLink = pathname.includes(name);

  return (
    <ListItem key={name} disablePadding data-tid="sidebar-linked-item">
      <Link to={link} className={classes.link}>
        <ListItemButton
          className={clsx(classes.route, isActiveLink && classes.activeRoute)}
          onClick={handleDrawerToggle}>
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          <ListItemText>{name}</ListItemText>
        </ListItemButton>
      </Link>
    </ListItem>
  );
};

export default LinkedItem;
