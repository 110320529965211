import React, { useEffect, useState } from 'react';
import { BasicSearch } from 'components';
import { useStyles } from './styles';
import {
  Box,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useDispatch, useSelector } from 'react-redux';
import { selectChanges } from 'store/common/changes/selector';
import { getChangesThunk } from 'store/common/changes/thunk';
import BasicPagination from 'components/BasicPagination';
import DetailsDialog from './Dialog';

const ChangesLog = () => {
  const classes = useStyles();
  const [serverErrors, setServerErrors] = useState(null);
  const { changes } = useSelector(selectChanges);
  const [searchQuery, setSearchQuery] = useState('');
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const dispatch = useDispatch();

  const handleDialogOpen = (index) => {
    setIsOpenDialog(index);
  };
  const handleDialogClose = () => {
    setIsOpenDialog(false);
  };

  useEffect(() => {
    fetchChanges(1);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchChanges(1, searchQuery);
    }, 500);
  }, [searchQuery]);

  const fetchChanges = (page = 1) => {
    dispatch(getChangesThunk({ page, q: searchQuery }));
  };

  return (
    <Card data-tid="changes-page">
      <Grid container>
        <Grid item xs={12} lg={3.25} className={classes.header}>
          <BasicSearch value={searchQuery} onChange={setSearchQuery} />
        </Grid>
      </Grid>

      <TableContainer className={classes.container} data-id="staff-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="tableTitle"> FULL NAME</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle">EVENT TITLE</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> CREATED DATE</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> UPDATED DATE</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> REASON</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> CHANGES</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {changes &&
              changes?.data.map((value, index) => {
                return (
                  <TableRow
                    hover
                    key={value.id}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleDialogOpen(index)}
                    className={classes.cursor}>
                    <TableCell>
                      <Typography variant="text">{value.full_name}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="text">{value.eventTitle}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="text">{value.created_at.slice(0, 10)}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="text">{value.updated_at.slice(0, 10)}</Typography>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'normal' }}>
                      <Typography variant="text">{value.reason}</Typography>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Changes">
                        <IconButton aria-label="changes" onClick={() => handleDialogOpen(index)}>
                          <CurrencyExchangeIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {isOpenDialog !== false && (
        <DetailsDialog
          isOpen={true}
          handleClose={handleDialogClose}
          changes={changes?.data[isOpenDialog]}
        />
      )}
      <BasicPagination
        count={changes?.last_page}
        page={changes?.current_page}
        onChange={(_, page) => fetchChanges(page)}
      />
    </Card>
  );
};

export default ChangesLog;
