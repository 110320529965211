import Box from '@mui/material/Box';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    data-tid="simple-tabpanel"
    {...other}>
    {value === index && <Box>{children}</Box>}
  </div>
);

export default TabPanel;
