import { makeStyles } from '@mui/styles';
import loginBackground from '../../../assets/images/login_background.jpg';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    minHeight: '100vh',
  },
  leftContent: {
    width: '60%',
    height: '100vh',
    backgroundRepeat: 'round',
    objectFit: 'cover',
    float: 'left',
    backgroundImage: `url(${loginBackground})`,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  rightContent: {
    margin: '0 auto',
  },
}));
