import React, { useState } from 'react';
import moment from 'moment';
import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/common/auth/selector';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ResponsiveDialog from 'components/ResponsiveDialog';

const Payments = ({ setPaymentState, state, showCalculations, errors }) => {
  const classes = useStyles();
  const { lists } = useSelector(selectUser);

  const [dialogs, setDialogs] = useState({
    edit: false,
    delete: false,
  });

  const handleDialogState = (dialogState) => {
    setDialogs({ ...dialogs, ...dialogState });
  };

  const deletePayment = (id) => {
    handleDialogState({ delete: false });
    const payments = Object.assign([], state.payments);

    setPaymentState(
      payments.filter((_, i) => {
        return i !== id;
      })
    );
  };

  const addPayment = () => {
    setPaymentState([
      ...state.payments,
      ...[
        {
          id: Date.now(),
          payment_name: 'payment',
          payment_type: 'check',
          amount: 0,
          payment_date: moment().format('YYYY-MM-DD'),
        },
      ],
    ]);
  };

  const setStateValue = (index, field, value) => {
    const payments = Object.assign([], state.payments);
    payments[index][field] = value;

    setPaymentState([...payments]);
  };

  return (
    <Box>
      <Box className={classes.addPaymentBtn}>
        <Button size="small" variant="contained" onClick={addPayment}>
          Add Payment
        </Button>
      </Box>

      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="tableTitle">Payment type</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle">Type</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle">Date</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle">Amount</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {state.payments &&
              state?.payments.map((payment, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{payment?.payment_name || ''}</Typography>
                    </TableCell>
                    <TableCell>
                      <FormControl fullWidth>
                        <InputLabel id="paymentType-select-label" color="secondary">
                          Cash
                        </InputLabel>
                        <Select
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                          value={payment?.payment_type || ''}
                          labelId="paymentType-select-label"
                          label="Cash"
                          variant="outlined"
                          color="secondary"
                          onChange={(e) => setStateValue(index, 'payment_type', e.target.value)}>
                          {Object.entries(lists?.payment_types || {}).map(([key, value]) => {
                            return (
                              <MenuItem key={key} value={key}>
                                {value}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        color="secondary"
                        variant="outlined"
                        type="date"
                        label="Date"
                        value={payment?.payment_date || ''}
                        // InputLabelProps={{ shrink: true }}
                        onChange={(e) => setStateValue(index, 'payment_date', e.target.value)}
                      />
                    </TableCell>

                    <TableCell sx={{ padding: '0px !important' }}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="amount" color="secondary">
                          Amount
                        </InputLabel>
                        <OutlinedInput
                          fullWidth
                          type="number"
                          color="secondary"
                          id="amount"
                          inputProps={{ min: 0 }}
                          // InputLabelProps={{ shrink: true }}
                          value={payment?.amount}
                          onChange={(e) => setStateValue(index, 'amount', e.target.value)}
                          startAdornment={<InputAdornment position="start">$</InputAdornment>}
                          label="Amount"
                          onWheel={(event) => event.target.blur()}
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      {payment?.payment_name == 'payment' && (
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleDialogState({ delete: index })}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {dialogs.delete && (
        <ResponsiveDialog
          label="Are you sure you want to delete this payment?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={() => deletePayment(dialogs.delete)}
          dataId={dialogs.delete}
        />
      )}
    </Box>
  );
};

export default Payments;
