import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },

  activeTab: {
    position: 'relative',
  },
  tab: {
    width: '100%',
    flexShrink: 1,
    maxWidth: 'unset',

    '&:hover': {
      background: theme.palette.custom.hover,
    },
  },
  mt: {
    marginTop: 20,
  },
  root: {
    width: '100%',
  },
  scroller: {
    flexGrow: 0,
  },
  fontSize: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  padding: {
    padding: '20px',
  },
}));
