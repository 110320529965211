import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

const BasicCheckbox = ({ label, checked, onChange }) => {
  const handleChange = (e) => {
    if (onChange) onChange(e.target.checked);
  };

  return (
    <FormControlLabel
      label={label}
      control={<Checkbox checked={checked} onChange={handleChange} color="secondary" />}
    />
  );
};

export default BasicCheckbox;
