import { handleActions } from 'redux-actions';
import { initialState } from '../../initialState';
import { GET_CHANGES, LOAD_CHANGES } from './actionType';

const initial = initialState.changes;

const reducer = handleActions(
  {
    [LOAD_CHANGES]: (state) => {
      return {
        ...state,
        isLoad: true,
      };
    },

    [GET_CHANGES]: (state, { payload }) => {
      return {
        ...state,
        changes: payload.changes,
        isLoad: false,
      };
    },
  },

  initial
);

export default reducer;
