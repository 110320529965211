import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  addPaymentBtn: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    marginTop: '20px',
    marginBottom: '30px',
  },

  deleteBtn: {
    background: 'none !important',
    border: 'none !important',
    cursor: 'pointer',
  },
}));
