import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: { padding: '25px' },

  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(3),
  },

  btnContainer: {
    display: 'flex',
    fontSize: '14px',
    marginLeft: 'auto !important',
  },
  button: {
    width: '100%',
    height: '40px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    //
    [theme.breakpoints.down('lg')]: {
      width: '100% !important',
    },
  },

  buttonWrapper: {
    display: 'flex',
    gap: '8px',
  },

  filterButton: {
    marginLeft: 'auto !important',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '0 !important',
    },
  },
  cursor: {
    cursor: 'pointer',
  },

  tableName: {
    width: '100% !important',
    maxWidth: '220px !important',
    display: 'block !important',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis !important',
  },
  marginLeft: {
    marginLeft: '20px !important',
    [theme.breakpoints.down('md')]: {
      marginLeft: '7px !important',
    },
  },
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px !important',
    },
  },
  badges: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: '350px',
    width: '100%',
    alignItems: 'center',
    height: '100%',
    flexWrap: 'wrap',
  },
}));
