import { Box } from '@mui/material';
import React from 'react';
import { LoginForm } from '../../../components/forms/index.js';
import { useStyles } from './styles.js';

const Login = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container} data-tid="login-page">
      <Box className={classes.leftContent}></Box>
      <Box className={classes.rightContent}>
        <LoginForm />
      </Box>
    </Box>
  );
};

export default Login;
