import { handleActions } from 'redux-actions';
import { initialState } from '../../initialState';
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMER,
  LOAD_CUSTOMER,
  UPDATE_CUSTOMER,
} from './actionType';

const initial = initialState.customers;

const reducer = handleActions(
  {
    [LOAD_CUSTOMER]: (state) => {
      return {
        ...state,
        isLoad: true,
      };
    },
    [CREATE_CUSTOMER]: (state, { payload }) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          data: [...[payload], ...state.customers.data],
        },
        isLoad: false,
      };
    },
    [GET_CUSTOMER]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
        customers: payload.customers,
        isLoad: false,
      };
    },
    [DELETE_CUSTOMER]: (state, { payload }) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          data: state.customers.data.filter((customer) => customer.id != payload),
        },
      };
    },
    [UPDATE_CUSTOMER]: (state, { payload }) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          data:
            state.customers?.data.map((customer) =>
              customer.id == payload.id ? payload : customer
            ) || [],
        },
      };
    },
  },

  initial
);

export default reducer;
