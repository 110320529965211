import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '10px',
    fontSize: '14px',
    height: '40px',
    [theme.breakpoints.down('md')]: {
      height: '35px !important',
    },
  },
  dialog: {
    overflowY: 'hidden',
    maxWidth: 'unset !important',
  },

  textAttribute: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      opacity: 0.9,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  backBtn: {
    marginLeft: 'auto !important',
    marginRight: '10px !important',
    background: '#f5f8fa !important',
  },
  cancel: {
    float: 'right !important',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px',
    marginTop: 'auto',
    flexWrap: 'nowrap !important',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap !important',
    },
  },

  header: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 20px',
    cursor: 'pointer',
    position: 'sticky',
  },

  MuiTabs: {
    styleOverrides: {
      root: {
        flexContainer: {
          display: 'flex !important',
          justifyContent: 'space-around !important',
          alignItems: 'center !important',
          width: '100%',
          marginBottom: '40px !important',
          maxWidth: 'unset !important',
        },
      },
    },
  },
  red: {
    // color: 'white !important',
    color: `${theme.palette.custom.red} !important`,
  },
  blue: {
    // color: 'white !important',
    color: `${theme.palette.custom.blue} !important`,
  },
  wrap: {
    maxWidth: '880px !important',
    width: '100% !important',
    minHeight: '300px !important',
    maxHeight: '620px !important',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },

  actions: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
  },

  tab: {
    whiteSpace: 'nowrap !important',
  },
}));
