import React, { useEffect, useRef, useState } from 'react';
import api from 'api';
import { useDispatch, useSelector } from 'react-redux';
import { SETTINGS_UPDATE_API } from 'api/api_routes';
import BasicCheckbox from 'components/BasicCheckbox';
import { Button, Grid, TextField } from '@mui/material';
import { selectUser } from 'store/common/auth/selector';
import { initThunk } from 'store/common/auth/thunk';

const AfterEventSms = ({ onSuccess }) => {
  const { settings } = useSelector(selectUser);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    after_days: settings?.after_days || 0,
    after_sms_text: settings?.after_sms_text || '',
    after_sms_enabled: +(settings?.after_sms_enabled || 0),
  });

  const setStateValues = (field, value) => {
    setState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onUpdate = async () => {
    const response = await api.post(SETTINGS_UPDATE_API, { settings: state });
    if (response.status) {
      onSuccess && onSuccess();
    }
    await dispatch(initThunk());
  };

  return (
    <Grid container spacing={{ xs: 3 }} sx={{ padding: '25px' }}>
      <Grid item xs={12}>
        <BasicCheckbox
          label="Enabled"
          checked={state.after_sms_enabled}
          onChange={(value) => setStateValues('after_sms_enabled', value ? 1 : 0)}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          type="number"
          color="secondary"
          label="Days after event"
          value={state.after_days}
          name="afterEvent"
          onChange={(e) => setStateValues('after_days', e.target.value)}
          placeholder="7"
          onWheel={(event) => event.target.blur()}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type="text"
          value={state.after_sms_text}
          multiline
          color="secondary"
          fullWidth
          label="Message"
          onChange={(e) => setStateValues('after_sms_text', e.target.value)}
          placeholder="Hello {first_name}, your event has ended."
          rows={8}
        />
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" onClick={onUpdate}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default AfterEventSms;
