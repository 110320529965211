import { createAction } from 'redux-actions';
import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMER,
  LOAD_CUSTOMER,
  UPDATE_CUSTOMER,
} from './actionType';

export const createCustomer = createAction(CREATE_CUSTOMER);
export const getCustomer = createAction(GET_CUSTOMER);
export const deleteCustomer = createAction(DELETE_CUSTOMER);
export const updateCustomer = createAction(UPDATE_CUSTOMER);
export const loadCustomer = createAction(LOAD_CUSTOMER);
