import api from 'api';
import { APPOINTMENTS_API } from 'api/api_routes';
import { handleUser } from '../auth/action';
import {
  createAppointment,
  deleteAppointment,
  getAppointment,
  loadAppointment,
  updateAppointment,
} from './action';

export const createAppointmentThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadAppointment());

    const response = await api.post(APPOINTMENTS_API, data, { onErrors });

    if (response.status) {
      dispatch(createAppointment(response.appointment));
    }
  };

export const getAppointmentThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadAppointment());

    const response = await api.get(`${APPOINTMENTS_API}`, data, { onErrors });

    if (response.status) {
      dispatch(getAppointment(response));
    }
  };

export const deleteAppointmentThunk =
  (id, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadAppointment());

    const response = await api.delete(`${APPOINTMENTS_API}/${id}`, { onErrors });

    if (response.status) {
      dispatch(deleteAppointment(id));
    }
  };

export const updateAppointmentThunk =
  ({ data, id, onErrors = null }) =>
  async (dispatch) => {
    dispatch(loadAppointment());
    const response = await api.put(`${APPOINTMENTS_API}/${id}`, data, { onErrors });

    if (response.status) {
      dispatch(updateAppointment(response.appointments));
    }
  };
