export const INIT_API = 'auth/init';
export const STAFF_API = 'api/users';
export const LOGIN_API = 'auth/login';
export const PROFILE_API = 'api/users';
export const EVENTS_API = 'api/events';
export const CALCULATION_API = 'api/calculate';
export const LOGOUT_API = 'auth/logout';
export const CHANGES_API = 'api/changes';
export const CUSTOMERS_API = 'api/customers';
export const COOKIE_API = 'sanctum/csrf-cookie';
export const EVENT_MENU_API = 'api/event-menus';
export const CALENDAR_API = 'api/calendar-items';
export const APPOINTMENTS_API = 'api/appointments';
export const RESET_PASS_API = 'auth/password/email';
export const CREATE_PASS_API = 'auth/password/reset';
export const SETTINGS_UPDATE_API = 'api/settings/update';
