import React from 'react';
import store from './store/store';
import App from './components/App';
import { globalTheme } from './themes';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { history } from './utils/history';
import '../src/assets/styles/index.scss';
import { HistoryRouter } from './router/HistoryRoute';
import { CssBaseline, ThemeProvider } from '@mui/material';
import './api';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={globalTheme}>
        <CssBaseline />
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
