import React, { useEffect } from 'react';
import { Alert, Box, Snackbar, Typography } from '@mui/material';
import { useStyles } from './styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Actions from '../../Actions';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuThunk } from 'store/common/settings/thunk';
import { selectSettings } from 'store/common/settings/selector';
import BasicPagination from 'components/BasicPagination';

const Menu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { event_menus } = useSelector(selectSettings);

  useEffect(() => {
    fetchUsers(1);
  }, []);

  const fetchUsers = (page = 1) => {
    dispatch(getMenuThunk({ page }));
  };

  return (
    <Box sx={{ width: '100%', padding: ' 0 20px' }}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="tableTitle">NAME</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="tableTitle"> DESCRIPTION</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="tableTitle"> ACTIONS</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {event_menus?.data &&
              event_menus?.data.map((event_menu) => (
                <TableRow
                  hover
                  key={event_menu?.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className={classes.box} align="left" component="th" scope="row">
                    <Typography variant="text" className={classes.content}>
                      {event_menu?.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="text" className={classes.content}>
                      {event_menu?.description}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Box sx={{ float: 'right' }}>
                      <Actions menuState={event_menu} />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <BasicPagination
        count={event_menus?.last_page}
        page={event_menus?.current_page}
        onChange={(_, page) => fetchUsers(page)}
      />
    </Box>
  );
};

export default Menu;
