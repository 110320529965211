import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const useStyles = makeStyles((theme) => ({
  link: {
    width: 'inherit',
    textDecoration: 'none',
    textTransform: 'capitalize',
  },

  route: {
    color: `${theme.palette.custom.darkGray} !important`,
  },

  activeRoute: {
    color: `${theme.palette.custom.blue} !important`,
    '&  *': {
      color: `inherit !important`,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'center !important',
    margin: '20px !important',
  },

  logo: {
    width: '250px',
    height: '120px',
    objectFit: 'scale-down',
  },

  icon: {
    minWidth: '40px !important',
  },

  userMenu: {
    background: 'unset !important',
    color: 'gray!important',
  },
}));
