import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { GlobalAppRouter } from '../../config/routerConfig';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/common/auth/selector';

export const UnauthorizedRoute = ({ component }) => {
  let role = 'admin';
  const { pathname } = useLocation();
  const { user } = useSelector(selectUser);

  if (user) {
    return <Navigate to={GlobalAppRouter[role].paths.base} state={{ from: pathname }} replace />;
  }
  return component;
};
