import React, { useState } from 'react';
import { Box, TextField, Button, Card, Typography } from '@mui/material';
import { useStyles } from '../styles';
import { Link } from 'react-router-dom';
import { authPaths } from '../../../constants/auth';
import logo from '../../../assets/images/logo.jpg';

import api from 'api';
import { RESET_PASS_API } from 'api/api_routes';

const ResetPasswordForm = () => {
  const classes = useStyles();
  const [serverErrors, setServerErrors] = useState(null);
  const [isSentMessage, setIsSentMessage] = useState('');

  const [state, setState] = useState({
    email: '',
  });

  const onSubmit = async (data) => {
    setServerErrors(null);
    const response = await api.post(RESET_PASS_API, state, { onErrors: setServerErrors });

    if (response.status) {
      setIsSentMessage(response.message);
    }
  };

  return (
    <>
      <Box data-tid="reset-password-form" className={classes.wrapper}>
        <img className={classes.logo} alt="Logo" src={logo} />
        <Card className={classes.form} autoComplete="off">
          {isSentMessage ? (
            <Typography variant="h4">{isSentMessage}</Typography>
          ) : (
            <>
              <TextField
                fullWidth
                type="email"
                required
                label="Email"
                color="secondary"
                value={state.email}
                onChange={(e) => setState({ email: e.target.value })}
                className={classes.textField}
                onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              />
              {serverErrors && <Typography variant="errors">{serverErrors?.email}</Typography>}
              <Box className={classes.btnContainer}>
                <Button
                  size="small"
                  className={classes.submit}
                  variant="contained"
                  onClick={onSubmit}>
                  <Typography variant="subtitle4">Submit</Typography>
                </Button>
                <Button size="small" className={classes.cancel} variant="contained">
                  <Typography variant="subtitle3">
                    <Link className={classes.textAttribute} to={authPaths.login} underline="none">
                      Cancel
                    </Link>
                  </Typography>
                </Button>
              </Box>
            </>
          )}
        </Card>
      </Box>
    </>
  );
};

export default ResetPasswordForm;
