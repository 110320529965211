import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { useStyles } from './styles';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/common/auth/selector';
import { InputNumber } from './InputNumber';

const Contract = ({ errors, allState, state, setContractState }) => {
  const classes = useStyles();
  const [isOpenServices, setIsOpenServices] = useState(false);
  const { lists } = useSelector(selectUser);

  const setStateValue = (field, value) => {
    setContractState({ ...state, [field]: value });
  };

  const setServiceStateValue = (field, value) => {
    setContractState({ ...state, service_list: { ...state.service_list, [field]: value } });
  };

  const toggleServices = () => {
    setIsOpenServices(!isOpenServices);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="menu-select-label" color="secondary">
              Menu *
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              label="Menu *"
              color="secondary"
              labelId="menu-select-label"
              value={state?.menu_id || -1}
              onChange={(e) => setStateValue('menu_id', e.target.value)}>
              <MenuItem value={-1}>Please select</MenuItem>
              {Object.entries(lists?.event_menus || {}).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {errors && <Typography variant="errors">{errors['contract.menu_id']}</Typography>}
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel htmlFor="cost_per_guest" color="secondary">
              {allState.type_id == 4 ? 'Cost per order *' : 'Cost per guest *'}
            </InputLabel>
            <InputNumber
              name="cost_per_guest"
              color="secondary"
              id="cost_per_guest"
              inputProps={{ min: 0 }}
              onChange={({ name, value }) => setStateValue(name, value)}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              label={allState.type_id == 4 ? 'Cost per order *' : 'Cost per guest *'}
              onWheel={(event) => event.target.blur()}
              initialValue={state?.cost_per_guest}
            />
          </FormControl>
          {errors && <Typography variant="errors">{errors['contract.cost_per_guest']}</Typography>}
        </Grid>

        {allState.type_id != 4 && (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="service_fee" color="secondary">
                Service Fee
              </InputLabel>

              <InputNumber
                name="service_fee"
                color="secondary"
                id="service_fee"
                inputProps={{ min: 0 }}
                onChange={({ name, value }) => setStateValue(name, value)}
                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                label="Service Fee"
                onWheel={(event) => event.target.blur()}
                initialValue={state.service_fee}
              />
            </FormControl>
            {errors && <Typography variant="errors">{errors['contract.service_fee']}</Typography>}
          </Grid>
        )}

        {allState?.version == 1 && (
          <Grid item xs={allState.type_id == 4 ? 12 : 6}>
            <FormControl fullWidth>
              <InputLabel htmlFor="discount" color="secondary">
                Discount
              </InputLabel>

              <InputNumber
                name="discount"
                color="secondary"
                id="discount"
                inputProps={{ min: 0 }}
                onChange={({ name, value }) => setStateValue(name, value)}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="Discount"
                onWheel={(event) => event.target.blur()}
                initialValue={state.discount}
              />
            </FormControl>
            {errors && <Typography variant="errors">{errors['contract.discount']}</Typography>}
          </Grid>
        )}
      </Grid>

      <Box className={classes.margin}>
        <Button size="small" variant="contained" onClick={toggleServices}>
          {isOpenServices ? <RemoveIcon /> : <AddIcon />} Other services
        </Button>
      </Box>

      {isOpenServices && (
        <>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="gratuity" color="secondary">
                  Gratuity
                </InputLabel>

                <InputNumber
                  name="gratitude"
                  color="secondary"
                  id="gratuity"
                  inputProps={{ min: 0 }}
                  onChange={({ name, value }) => setServiceStateValue(name, value)}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Gratuity"
                  onWheel={(event) => event.target.blur()}
                  initialValue={state.service_list.gratitude || ''}
                />
              </FormControl>
              {errors && (
                <Typography variant="errors">
                  {errors['contract.service_list.gratitude']}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="bar" color="secondary">
                  Bar & Bartenders
                </InputLabel>
                <InputNumber
                  name="security"
                  color="secondary"
                  id="bar"
                  inputProps={{ min: 0 }}
                  onChange={({ name, value }) => setServiceStateValue(name, value)}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Bar & Bartenders"
                  onWheel={(event) => event.target.blur()}
                  initialValue={state.service_list.security || ''}
                />
              </FormControl>
              {errors && (
                <Typography variant="errors">{errors['contract.service_list.security']}</Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="ceremony" color="secondary">
                  Ceremony onsite
                </InputLabel>

                <InputNumber
                  name="ceremony"
                  color="secondary"
                  id="ceremony"
                  inputProps={{ min: 0 }}
                  onChange={({ name, value }) => setServiceStateValue(name, value)}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Ceremony onsite"
                  onWheel={(event) => event.target.blur()}
                  initialValue={state.service_list.ceremony || ''}
                />
              </FormControl>
              {errors && (
                <Typography variant="errors">{errors['contract.service_list.ceremony']}</Typography>
              )}
            </Grid>

            {allState?.version == 1 && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="other" color="secondary">
                    Other
                  </InputLabel>

                  <InputNumber
                    name="other"
                    color="secondary"
                    id="other"
                    inputProps={{ min: 0 }}
                    onChange={({ name, value }) => setServiceStateValue(name, value)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    label="Other"
                    onWheel={(event) => event.target.blur()}
                    initialValue={state.service_list.other || ''}
                  />
                </FormControl>
                {errors && (
                  <Typography variant="errors">{errors['contract.service_list.other']}</Typography>
                )}
              </Grid>
            )}

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="chair_table_removal" color="secondary">
                  Chair & Table Removal
                </InputLabel>

                <InputNumber
                  name="lightning"
                  color="secondary"
                  id="chair_table_removal"
                  inputProps={{ min: 0 }}
                  onChange={({ name, value }) => setServiceStateValue(name, value)}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Chair & Table Removal"
                  onWheel={(event) => event.target.blur()}
                  initialValue={state.service_list.lightning || ''}
                />
              </FormControl>
              {errors && (
                <Typography variant="errors">
                  {errors['contract.service_list.lightning']}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="cocktail_hour" color="secondary">
                  Cocktail hour
                </InputLabel>

                <InputNumber
                  name="cocktail_hour"
                  color="secondary"
                  id="cocktail_hour"
                  inputProps={{ min: 0 }}
                  onChange={({ name, value }) => setServiceStateValue(name, value)}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Cocktail hour"
                  onWheel={(event) => event.target.blur()}
                  initialValue={state.service_list.cocktail_hour || ''}
                />
              </FormControl>
              {errors && (
                <Typography variant="errors">
                  {errors['contract.service_list.cocktail_hour']}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="tax" color="secondary">
                  Tax
                </InputLabel>

                <InputNumber
                  name="tax"
                  color="secondary"
                  id="tax"
                  inputProps={{ min: 0 }}
                  onChange={({ name, value }) => setServiceStateValue(name, value)}
                  startAdornment={<InputAdornment position="start">%</InputAdornment>}
                  label="Tax"
                  onWheel={(event) => event.target.blur()}
                  initialValue={state.service_list.tax || ''}
                />
              </FormControl>
              {errors && (
                <Typography variant="errors">{errors['contract.service_list.tax']}</Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="valet_parking" color="secondary">
                  Valet Parking
                </InputLabel>

                <InputNumber
                  name="valet_parking"
                  color="secondary"
                  id="valet_parking"
                  inputProps={{ min: 0 }}
                  onChange={({ name, value }) => setServiceStateValue(name, value)}
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  label="Valet Parking"
                  onWheel={(event) => event.target.blur()}
                  initialValue={state.service_list.valet_parking || ''}
                />
              </FormControl>
              {errors && (
                <Typography variant="errors">
                  {errors['contract.service_list.valet_parking']}
                </Typography>
              )}
            </Grid>

            {(allState?.version == 2 || allState?.version == null) && (
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="security_new" color="secondary">
                      Security
                    </InputLabel>

                    <InputNumber
                      name="security_new"
                      color="secondary"
                      id="security_new"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Security"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.security_new || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">
                      {errors['contract.service_list.security_new']}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="lightning_new" color="secondary">
                      Special Events Lighting
                    </InputLabel>

                    <InputNumber
                      name="lightning_new"
                      color="secondary"
                      id="lightning_new"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Special Events Lighting"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.lightning_new || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">
                      {errors['contract.service_list.lightning_new']}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="led_screen_new" color="secondary">
                      LED Screen
                    </InputLabel>

                    <InputNumber
                      name="led_screen_new"
                      color="secondary"
                      id="led_screen_new"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="LED Screen"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.led_screen_new || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">
                      {errors['contract.service_list.lightning_new']}
                    </Typography>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default Contract;
