import React, { useEffect, useState } from 'react';
import { BasicSearch } from 'components';
import {
  Alert,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { selectUser } from 'store/common/auth/selector';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { BasicDatePicker } from 'components';
import { useStyles } from './styles';
import AppointmentPopup from './Dialog/AppointmentPopup';
import { useDispatch, useSelector } from 'react-redux';
import BasicPagination from 'components/BasicPagination';
import { deleteAppointmentThunk, getAppointmentThunk } from 'store/common/appointments/thunk';
import { selectAppointment } from 'store/common/appointments/selector';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ResponsiveDialog from 'components/ResponsiveDialog';
import moment from 'moment';

const Appointments = () => {
  const initialState = {
    q: '',
    type_id: null,
    date_start: null,
    date_end: null,
    order_by: 'date',
    descending: false,
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const { appointments } = useSelector(selectAppointment);
  const { data } = useSelector(selectAppointment);
  const [filters, setFilters] = useState({
    ...initialState,
    date_start: moment().format('YYYY-MM-DD'),
    date_end: moment().add(30, 'day').format('YYYY-MM-DD'),
    // descending: false,
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const { lists } = useSelector(selectUser);

  const resetFilter = () => {
    setFilters(initialState);
  };

  const camelCase = (str) => {
    return str && str.charAt(0).toUpperCase() + str.substring(1);
  };

  // region local state management
  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const setFiltersValue = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });

    if (event) event.stopPropagation();
  };
  // endregion

  // region Fetch first page of appointments on didMount
  useEffect(() => {
    fetchAppointmentsAction(1);
  }, []);
  // endregion

  // region fetch appointments by search query
  useEffect(() => {
    setTimeout(() => {
      fetchAppointmentsAction(1);
    }, 500);
  }, [filters]);
  // endregion

  // region Actions
  const fetchAppointmentsAction = (page = 1) => {
    dispatch(getAppointmentThunk({ page, ...filters }));
  };

  const deleteAppointmentAction = async (appointmentId) => {
    await dispatch(deleteAppointmentThunk(appointmentId));
    handleDialogState({ delete: false });
    setShowDeleted(true);
    fetchAppointmentsAction(1);
  };

  const onPopupSuccess = () => {
    setShowSuccess(true);
    fetchAppointmentsAction(1);
  };
  // endregion

  return (
    <Card data-tid="appointment-page">
      <Grid container className={classes.header} spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} lg={3}>
          <BasicSearch value={filters.q} onChange={(value) => setFiltersValue('q', value)} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Box className={classes.badges}>
            <Box>
              <Typography variant="badgeTitle"> Passed: </Typography>
              <Typography variant="badgeValue">{data?.stats.passed}</Typography>
            </Box>
            <Box>
              <Typography variant="badgeTitle"> Total: </Typography>
              <Typography variant="badgeValue">{data?.stats.total}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => handleDialogState({ add: true })}>
            <AddIcon />
            <Typography variant="h5">Add appointment</Typography>
          </Button>
        </Grid>
        {/* Filters */}

        <Grid item xs={12} lg={12}>
          <Box>
            <Grid container spacing={{ xs: 1.5 }}>
              <Grid item xs={12} lg={2.5}>
                <FormControl fullWidth>
                  <InputLabel id="types-select-label" color="secondary">
                    Appointment types
                  </InputLabel>
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="types-select-label"
                    label="Appointment types"
                    color="secondary"
                    value={filters.type_id || ''}
                    onChange={(e) => setFiltersValue('type_id', e.target.value)}>
                    {Object.entries(lists?.appointment_types || {}).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={2.5}>
                <FormControl fullWidth>
                  <InputLabel id="orderLabel-label" color="secondary">
                    Order by
                  </InputLabel>
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="orderLabel-label"
                    label="Order By"
                    color="secondary"
                    value={filters.order_by}
                    onChange={(e) => setFiltersValue('order_by', e.target.value)}>
                    <MenuItem value="first_name">Full name</MenuItem>
                    <MenuItem value="title">Appointment Type</MenuItem>
                    <MenuItem value="date">Date</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={2.5}>
                <FormControl fullWidth>
                  <InputLabel id="orderby-select-label" color="secondary">
                    Order
                  </InputLabel>
                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    labelId="orderby-select-label"
                    label="Order"
                    color="secondary"
                    value={filters.descending}
                    onChange={(e) => setFiltersValue('descending', e.target.value)}>
                    <MenuItem value="false">ASC</MenuItem>
                    <MenuItem value="true">DESC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} lg={4}>
                <BasicDatePicker
                  className="datepicker"
                  value={[filters.date_start, filters.date_end]}
                  onChange={(e) => {
                    if (e) {
                      if (e.length == 1) {
                        setFiltersValue('date_start', e[0].format('YYYY-MM-DD'));
                      } else if (e.length > 1) {
                        setFilters({
                          ...filters,
                          date_start: e[0].format('YYYY-MM-DD'),
                          date_end: e[1].format('YYYY-MM-DD'),
                        });
                      }
                    }
                  }}
                  setFiltersValue={setFiltersValue}
                  setFilters={setFilters}
                />
              </Grid> */}
              <Grid item xs={12} lg={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  color="secondary"
                  label="Start Date"
                  value={filters?.date_start || ''}
                  onChange={(e) => setFiltersValue('date_start', e.target.value)}
                  id="filled-size-normal"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="date"
                  color="secondary"
                  label="End Date"
                  value={filters?.date_end || ''}
                  onChange={(e) => setFiltersValue('date_end', e.target.value)}
                  id="filled-size-normal"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={0.5}
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <Tooltip title="Reset">
                  <IconButton onClick={resetFilter}>
                    <FilterAltOffIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {dialogs.add && (
        <AppointmentPopup
          mode="add"
          handleClose={() => handleDialogState({ add: false })}
          onSuccess={onPopupSuccess}
        />
      )}

      {dialogs.edit !== false && (
        <AppointmentPopup
          mode="edit"
          handleClose={() => handleDialogState({ edit: false })}
          appointmentState={dialogs.edit}
          onSuccess={onPopupSuccess}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this appointment?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteAppointmentAction}
          dataId={dialogs.delete}
        />
      )}

      <TableContainer className={classes.container} data-id="appointments-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="tableTitle"> FULL NAME</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> APPOINTMENT TYPE</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> DATE</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> TIME</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> TOUR PERSON</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> SMS</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> ACTIONS</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointments &&
              appointments?.data.map((value) => {
                return (
                  <TableRow
                    hover
                    className={classes.cursor}
                    key={value?.id}
                    onClick={() => handleDialogState({ edit: value })}>
                    <TableCell>
                      <Typography variant="text">{value?.first_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">{value?.title}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">
                        {value.dateTime
                          ? moment(value.dateTime).utc(true).local().format('MM.DD.YYYY')
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">
                        {value?.dateTime
                          ? moment(value.dateTime).utc(true).local().format('h:mm A')
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ maxWidth: '100px', overflow: 'hidden' }}>
                      <Box
                        component="div"
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}>
                        <Typography variant="text">{value?.tour_person}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">
                        {value?.sent_sms ? <CheckIcon color="success" /> : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <ButtonGroup>
                        <Tooltip title="Edit">
                          <IconButton aria-label="Edit">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            color="error"
                            onClick={(e) => handleDialogState({ delete: value.id }, e)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <BasicPagination
        count={appointments?.last_page}
        page={appointments?.current_page}
        onChange={(_, page) => fetchAppointmentsAction(page)}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowSuccess(false)}
        open={showSuccess}
        key={'bottom' + 'center' + 'success'}>
        <Alert severity="success" variant="filled">
          Your changes were saved successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowDeleted(false)}
        open={showDeleted}
        key={'bottom' + 'center' + 'deleted'}>
        <Alert severity="success" variant="filled">
          The appointment was deleted successfully!
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default Appointments;
