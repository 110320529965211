import React, { forwardRef, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Grow,
  InputLabel,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import Grid from '@mui/material/Grid';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import FormattedInputs from 'components/NumberFormat';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'store/common/auth/selector';
import { APPOINTMENTS_API, STAFF_API } from 'api/api_routes';
import api from 'api';
import moment from 'moment';
import { createAppointment, updateAppointment } from 'store/common/appointments/action';

import { InputNumber } from '../../event/Events/Contract/InputNumber';
import { formatNumber } from 'utils/formatNumber';
import { CALCULATION_API } from 'api/api_routes';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const AppointmentPopup = ({ handleClose, appointmentState, mode, onSuccess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const { lists } = useSelector(selectUser);

  const [staff, setStaff] = useState([]);

  const [isSending, setIsSending] = useState(false);
  const [state, setState] = useState({
    ...appointmentState,
    first_name: appointmentState?.first_name || '',
    title: 'Tour',
    phone: appointmentState?.phone || '',
    notes: appointmentState?.notes || '',
    tour_person: appointmentState?.tour_person || '',
    hear_about_us: appointmentState?.hear_about_us || '',

    computedDate: appointmentState?.dateTime
      ? moment(appointmentState?.dateTime)?.utc(true)?.local()?.format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),

    computedTime: appointmentState?.dateTime
      ? moment(appointmentState?.dateTime)?.utc(true)?.local()?.format('HH:mm:00')
      : moment().format('HH:mm:00'),

    services: {
      guests_number: '',
      discount: '',

      cost_per_guest: '',
      service_fee: 18,

      grand_total: '',

      gratitude: 0,
      lightning: 0,
      security: 0,
      cocktail_hour: 0,
      valet_parking: 0,
      ceremony: 0,
      tax: 0,

      security_new: 0,
      lightning_new: 0,
      led_screen_new: 0,

      ...appointmentState?.services,
    },
  });

  function setServiceStateValue(field, value) {
    setState({
      ...state,
      services: {
        ...state.services,
        [field]: value,
      },
    });
  }

  const grandTotal = (format = true) => {
    let grandTotal = 0;
    let costPerGuest = 0;

    let services = Object.keys(state?.services)
      .filter(
        (key) =>
          [
            'tax',
            'gratitude',
            'valet_parking',
            'guests_number',
            'cost_per_guest',
            'discount',
            'service_fee',
            'grand_total',
          ].indexOf(key) == -1
      )
      .map((key) => state?.services[key]);

    let serviceList = services.reduce((acc, value) => {
      return acc + +value;
    }, 0);

    // serviceList -= Number(state.discount);

    costPerGuest = +state?.services.cost_per_guest * state.services.guests_number;

    grandTotal = serviceList + Number(costPerGuest);

    grandTotal += (grandTotal * Number(state.services.service_fee)) / 100;

    if (state.services.tax) {
      grandTotal += (grandTotal * Number(state.services.tax)) / 100;
    }

    grandTotal += Number(state.services.gratitude) + Number(state.services.valet_parking);

    // grandTotal -= Number(state.discount);

    let num = parseFloat(grandTotal);
    return format ? formatNumber(num) : num;
  };

  useEffect(() => {
    get_staff();
  }, []);

  const get_staff = async (data) => {
    const response = await api.get(`${STAFF_API}`, {
      position_id: 1,
      limit: 1000,
    });

    if (response.status) {
      setStaff(response.users.data.map((user) => `${user.first_name} ${user.last_name}`));
    }
  };

  const setStateValue = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const sendForm = async () => {
    setIsSending(true);

    let date = moment(`${state.computedDate} ${state.computedTime}`).utc();

    let data = {
      ...state,

      computedDate: state.computedDate ? date.format('YYYY-MM-DD') : null,
      computedTime: state.computedTime ? date.format('HH:mm:ss') : null,
    };

    data.services.grand_total = grandTotal(false);

    if (mode == 'add') {
      const response = await api.post(APPOINTMENTS_API, data, { onErrors: setErrors });

      if (response.status) {
        dispatch(createAppointment(response.appointment));
        onSuccess && onSuccess();

        handleClose();
      }
    } else {
      const response = await api.put(`${APPOINTMENTS_API}/${data.id}`, data, {
        onErrors: setErrors,
      });

      if (response.status) {
        dispatch(updateAppointment(response.appointment));
        onSuccess && onSuccess();

        handleClose();
      }
    }

    setIsSending(false);
  };

  const downloadPDF = async () => {
    var gt = grandTotal(false);

    const response = await api.post(
      CALCULATION_API,
      {
        grand_total: gt,

        first_name: state?.first_name,
        service_fee: state?.services.service_fee,
        guests_number: state?.services.guests_number,
        cost_per_guest: state?.services.cost_per_guest,
        discount: state?.services.discount,
        notes: state?.notes,

        service_list: {
          gratitude: state?.services.gratitude,
          lightning: state?.services.lightning,
          lightning_new: state?.services.lightning_new,
          security: state?.services.security,
          security_new: state?.services.security_new,
          cocktail_hour: state?.services.cocktail_hour,
          valet_parking: state?.services.valet_parking,
          ceremony: state?.services.ceremony,
          tax: state?.services.tax,
          other: state?.services.other,
          led_screen_new: state?.services.led_screen_new,
        },
      },
      { onErrors: setErrors }
    );

    if (response.status) {
      setErrors([]);
      let first_name = state.first_name.trim().toLowerCase().replaceAll(' ', '_');
      let date = moment().format('YYYY-MM-DD');
      //   alert(`${name}_estimate.${date}`);

      var link = document.createElement('a');
      link.href =
        process.env.REACT_APP_API_URL +
        `event/download/estimate?name=${first_name}_estimate.${date}.pdf`;
      link.download = 'estimate.pdf';
      link.click();
    }
  };

  return (
    <Dialog
      className={classes.overflow}
      maxWidth="md"
      open={true}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">
          {mode == 'add' ? 'Add new appointment' : 'Edit appointment'}
        </Typography>
        <ClearIcon className={classes.cancel} onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent className={classes.content}>
        <Box className={classes.container} noValidate autoComplete="off">
          <Box>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Full name"
                  required
                  type="text"
                  color="secondary"
                  value={state?.first_name}
                  onChange={(e) => setStateValue('first_name', e.target.value)}
                  className={classes.marginBottom}
                />
                {errors && <Typography variant="errors">{errors?.first_name}</Typography>}
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="appointment-select-label" color="secondary">
                    Appointment type
                  </InputLabel>

                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={state?.title}
                    labelId="appointment-select-label"
                    label="Appointment type"
                    variant="outlined"
                    color="secondary"
                    onChange={(e) => setStateValue('title', e.target.value)}>
                    {Object.entries(lists?.appointment_types || {}).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {errors && <Typography variant="errors">{errors?.appointment_type}</Typography>}
              </Grid>

              <Grid item xs={6}>
                <FormattedInputs
                  value={state?.phone}
                  label="Phone number"
                  required
                  onChange={(value) => setStateValue('phone', value)}
                />
                {errors && <Typography variant="errors">{errors?.phone}</Typography>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  value={state?.computedDate}
                  onChange={(e) => setStateValue('computedDate', e.target.value)}
                  variant="outlined"
                  type="date"
                  label="Appointment Date"
                  required
                  color="secondary"
                  className={classes.marginBottom}
                  id="filled-size-small"
                />
                {errors && <Typography variant="errors">{errors?.computedDate}</Typography>}
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  value={state?.computedTime}
                  onChange={(e) => {
                    setStateValue('computedTime', e.target.value);
                  }}
                  variant="outlined"
                  type="time"
                  label="Time"
                  required
                  color="secondary"
                  className={classes.marginBottom}
                  id="filled-size-small"
                />

                {errors && <Typography variant="errors">{errors?.computedTime}</Typography>}
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="appointment-select-label" color="secondary">
                    Staff
                  </InputLabel>

                  <Select
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                    value={state?.tour_person}
                    labelId="appointment-select-label"
                    label="Staff"
                    variant="outlined"
                    color="secondary"
                    onChange={(e) => setStateValue('tour_person', e.target.value)}>
                    <MenuItem value={null}>Please select</MenuItem>
                    {staff.map((staff) => {
                      return (
                        <MenuItem key={staff} value={staff}>
                          {staff}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={state?.hear_about_us}
                  onChange={(e) => setStateValue('hear_about_us', e.target.value)}
                  type="text"
                  color="secondary"
                  label="How did you hear about us?"
                  className={classes.marginBottom}
                  id="filled-size-small"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={state?.notes}
                  onChange={(e) => setStateValue('notes', e.target.value)}
                  type="text"
                  multiline
                  color="secondary"
                  label="Notes"
                  className={classes.marginBottom}
                  id="filled-size-small"
                />
              </Grid>

              <Grid item xs={12}>
                <Accordion elevation={0} square style={{ border: '1px solid #c4c4c4' }}>
                  <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
                    <Typography>Price estimation</Typography>
                    <Typography style={{ marginLeft: 'auto', marginRight: '10px' }}>
                      <strong>Grand Total: ${grandTotal()}</strong>
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Box spacing={2}>
                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="guests_number" color="secondary">
                              Total Guest Count
                            </InputLabel>
                            <InputNumber
                              name="guests_number"
                              color="secondary"
                              type="number"
                              id="guests_number"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              label="Total Guest Count"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.guests_number}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">{errors['guests_number']}</Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="service_fee" color="secondary">
                              Service Fee
                            </InputLabel>

                            <InputNumber
                              name="service_fee"
                              color="secondary"
                              id="service_fee"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">%</InputAdornment>}
                              label="Service Fee"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.service_fee}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">{errors['service_fee']}</Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="cost_per_guest" color="secondary">
                              Cost Per Guest *
                            </InputLabel>
                            <InputNumber
                              name="cost_per_guest"
                              color="secondary"
                              id="cost_per_guest"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Cost Per Guest *"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.cost_per_guest}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">{errors['cost_per_guest']}</Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="gratuity" color="secondary">
                              Gratuity
                            </InputLabel>

                            <InputNumber
                              name="gratitude"
                              color="secondary"
                              id="gratuity"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Gratuity"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.gratitude || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">
                              {errors['service_list.gratitude']}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="bar" color="secondary">
                              Bar & Bartenders
                            </InputLabel>
                            <InputNumber
                              name="security"
                              color="secondary"
                              id="bar"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Bar & Bartenders"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.security || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">
                              {errors['service_list.security']}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="ceremony" color="secondary">
                              Ceremony Onsite
                            </InputLabel>

                            <InputNumber
                              name="ceremony"
                              color="secondary"
                              id="ceremony"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Ceremony Onsite"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.ceremony || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">
                              {errors['service_list.ceremony']}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="chair_table_removal" color="secondary">
                              Chair & Table Removal
                            </InputLabel>

                            <InputNumber
                              name="lightning"
                              color="secondary"
                              id="chair_table_removal"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Chair & Table Removal"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.lightning || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">
                              {errors['service_list.lightning']}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="cocktail_hour" color="secondary">
                              Cocktail Hour
                            </InputLabel>

                            <InputNumber
                              name="cocktail_hour"
                              color="secondary"
                              id="cocktail_hour"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Cocktail Hour"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.cocktail_hour || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">
                              {errors['service_list.cocktail_hour']}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="tax" color="secondary">
                              Tax
                            </InputLabel>

                            <InputNumber
                              name="tax"
                              color="secondary"
                              id="tax"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">%</InputAdornment>}
                              label="Tax"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.tax || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">{errors['service_list.tax']}</Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="valet_parking" color="secondary">
                              Valet Parking
                            </InputLabel>

                            <InputNumber
                              name="valet_parking"
                              color="secondary"
                              id="valet_parking"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Valet Parking"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.valet_parking || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">
                              {errors['service_list.valet_parking']}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="security_new" color="secondary">
                              Security
                            </InputLabel>

                            <InputNumber
                              name="security_new"
                              color="secondary"
                              id="security_new"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Security"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.security_new || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">
                              {errors['service_list.security_new']}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="lightning_new" color="secondary">
                              Special Events Lighting
                            </InputLabel>

                            <InputNumber
                              name="lightning_new"
                              color="secondary"
                              id="lightning_new"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Special Events Lighting"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.lightning_new || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">
                              {errors['service_list.lightning_new']}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="led_screen_new" color="secondary">
                              LED Screen
                            </InputLabel>

                            <InputNumber
                              name="led_screen_new"
                              color="secondary"
                              id="led_screen_new"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) => setServiceStateValue(name, value)}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="LED Screen"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.led_screen_new || ''}
                            />
                          </FormControl>
                          {errors && (
                            <Typography variant="errors">
                              {errors['service_list.lightning_new']}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="discount" color="secondary">
                              Discount
                            </InputLabel>
                            <InputNumber
                              name="discount"
                              color="secondary"
                              type="number"
                              id="discount"
                              inputProps={{ min: 0 }}
                              onChange={({ name, value }) =>
                                setServiceStateValue(name, Number(value))
                              }
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Discount"
                              onWheel={(event) => event.target.blur()}
                              initialValue={state.services.discount}
                            />
                          </FormControl>
                          {errors && <Typography variant="errors">{errors['discount']}</Typography>}
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: 'auto' }}
                            onClick={downloadPDF}>
                            <Typography variant="subtitle4">Download PDF</Typography>
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Box>

          <Box className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              className={classes.cancel}
              onClick={handleClose}>
              <Typography variant="subtitle3">Cancel</Typography>
            </Button>

            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={sendForm}
              disabled={isSending}>
              <Typography variant="subtitle4">{mode == 'add' ? 'Create' : 'Save'}</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentPopup;
