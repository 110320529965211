import React, { useMemo } from 'react';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import FormattedInputs from 'components/NumberFormat';
import { selectCustomer } from 'store/common/customers/selector';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerThunk } from 'store/common/customers/thunk';
import { getAppointmentThunk } from 'store/common/appointments/thunk';

const Customer = ({ setCustomerState, errors, allState, state, autofill }) => {
  const { customers } = useSelector(selectCustomer);
  const dispatch = useDispatch();

  const values = useMemo(() => {
    if (!autofill) return [];
    return Object.values(customers?.data || {});
  }, [customers]);

  const setStateValue = (field, value) => {
    setCustomerState({ ...state, [field]: value });
  };

  const fetchCustomers = (q) => {
    if (!autofill) return;
    dispatch(getCustomerThunk({ q }));
  };

  const selectUser = (_, user) => {
    // setAutoComplete(true);
    if (!autofill) return;
    setCustomerState({
      ...state,
      address: user.address || '',
      phone_number: user.phone_number || '',
      alt_phone_number: user.alt_phone_number || '',
      dl_expire_date: user.dl_expire_date || '',
      dl_number: user.dl_number || '',
      email: user.email || '',
      full_name: user.full_name || '',
      autoComplete: true,
    });
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Autocomplete
            id="country-select-demo"
            options={values}
            autoHighlight
            freeSolo
            getOptionLabel={(option) => (option.full_name ? option.full_name : state.full_name)}
            defaultValue={state?.full_name}
            onChange={selectUser}
            renderOption={(props, option) => (
              <Box
                component="li"
                key={option.id}
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}>
                {option.full_name} ({option.email})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required
                label="Full Name"
                color="secondary"
                variant="outlined"
                type="text"
                value={state?.full_name}
                onChange={(e) => {
                  setStateValue('full_name', e.target.value);
                  fetchCustomers(e.target.value);
                  // fetchAppointments(e.target.value);
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}
          />
          {errors && <Typography variant="errors">{errors['customer.full_name']}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Home Address"
            color="secondary"
            required
            value={state?.address || ''}
            onChange={(e) => setStateValue('address', e.target.value)}
          />
          {errors && <Typography variant="errors">{errors['customer.address']}</Typography>}
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            type="email"
            label="Email"
            required
            value={state?.email || ''}
            color="secondary"
            onChange={(e) => setStateValue('email', e.target.value)}
          />

          {errors && <Typography variant="errors">{errors['customer.email']}</Typography>}
        </Grid>

        <Grid item xs={3}>
          <FormattedInputs
            required
            label="Phone Number"
            value={state.phone_number || ''}
            onChange={(value) => setStateValue('phone_number', value)}
          />
          {errors && <Typography variant="errors">{errors['customer.phone_number']}</Typography>}
        </Grid>

        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="DL#"
            value={state?.dl_number || ''}
            color="secondary"
            onChange={(e) => setStateValue('dl_number', e.target.value)}
            // InputLabelProps={{ shrink: true }}
          />
          {errors && <Typography variant="errors">{errors['customer.dl_number']}</Typography>}
        </Grid>

        {allState?.version == 1 && (
          <>
            <Grid item xs={6}>
              <FormattedInputs
                label="Alternate Phone"
                value={state?.alt_phone_number ? state?.alt_phone_number : ''}
                onChange={(value) => setStateValue('alt_phone_number', value)}
              />
              {errors && (
                <Typography variant="errors">{errors['customer.alt_phone_number']}</Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="date"
                label="DL expiration date"
                color="secondary"
                value={state?.dl_expire_date || ' '}
                onChange={(e) => setStateValue('dl_expire_date', e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
              {errors && (
                <Typography variant="errors">{errors['customer.dl_expire_date']}</Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Customer;
