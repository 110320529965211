import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: { padding: '25px' },

  header: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(3),
  },

  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '10px',
    fontSize: '14px',
    position: 'relative',
  },
  button: {
    height: '40px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    marginLeft: 'auto !important',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  cursor: {
    cursor: 'pointer',
  },
  badgeWrapper: {
    [theme.breakpoints.down('md')]: {
      marginTop: '20px !important',
    },
  },
  wrapper: {
    display: 'flex  !important',
    flexWrap: 'nowrap  !important',
    [theme.breakpoints.down('md')]: {
      display: 'unset  !important',
      flexWrap: 'unset  !important',
    },
  },
  badges: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '180px',
    alignItems: 'center',
    height: '100%',
    flexWrap: 'wrap',
  },
}));
