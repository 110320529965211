import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    overflow: 'hidden !important',
    padding: '15px!important',
    minWidth: '320px',
    width: '100%',
  },
  overflow: {
    overflowY: 'hidden!important',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '15px',
    cursor: 'pointer',
  },

  body: {
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: { display: 'flex', alignItems: 'center', flexWrap: 'no-wrap' },
}));
