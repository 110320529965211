import { handleActions } from 'redux-actions';
import { initialState } from '../../initialState';
import { LOAD_EVENT, CREATE_EVENT, GET_EVENT, DELETE_EVENT, UPDATE_EVENT } from './actionType';

const initial = initialState.events;
const reducer = handleActions(
  {
    [LOAD_EVENT]: (state) => {
      return {
        ...state,
        isLoad: true,
      };
    },
    [CREATE_EVENT]: (state, { payload }) => {
      return {
        ...state,
        events: {
          ...state.events,
          data: [...[payload], ...state.events.data],
        },
        isLoad: false,
      };
    },
    [GET_EVENT]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
        events: payload?.events,
        isLoad: false,
      };
    },
    [DELETE_EVENT]: (state, { payload }) => {
      return {
        ...state,
        events: {
          ...state.events,
          data: state.events?.data.filter((event) => event?.id != payload),
        },
      };
    },
    [UPDATE_EVENT]: (state, { payload }) => {
      // return {
      //   ...state,
      //   appointments: {
      //     ...state.appointments,
      //     data:
      //       state.appointments?.data.map((appointment) =>
      //         appointment?.id == payload?.id ? payload : appointment
      //       ) || [],
      //   },
      // };
    },
  },

  initial
);

export default reducer;
