import React, { useEffect } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/common/auth/selector';

const EventInfo = ({ errors, state, setState }) => {
  const { lists } = useSelector(selectUser);

  const setStateValue = async (field, value) => {
    let data = {
      [field]: value,
    };

    if (field == 'type_id' && !state.id) {
      let menu_id = -1;
      let name = lists.event_types[value];
      let regex = new RegExp(name, 'gi');

      Object.keys(lists.event_menus).forEach((item) => {
        let match = lists.event_menus[item].match(regex);
        if (match && match.length) {
          menu_id = item;
        }
      });

      data['contract'] = { ...state.contract, menu_id };
    }

    setState({ ...state, ...data });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            color="secondary"
            required
            label="Event Name"
            id="filled-size-normal"
            value={state?.name}
            onChange={(e) => setStateValue('name', e.target.value)}
          />
          {errors && <Typography variant="errors">{errors?.name}</Typography>}
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="date"
            color="secondary"
            required
            label="Date"
            value={state?.event_date || ''}
            onChange={(e) => setStateValue('event_date', e.target.value)}
            id="filled-size-normal"
            InputLabelProps={{ shrink: true }}
          />
          {errors && <Typography variant="errors">{errors?.event_date}</Typography>}
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            type="time"
            color="secondary"
            label="Time"
            value={state?.event_start || ''}
            onChange={(e) => setStateValue('event_start', e.target.value)}
            id="filled-size-normal"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="eventType-select-label" color="secondary">
              Event type *
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              value={state?.type_id || -1}
              labelId="eventType-select-label"
              label="Event type *"
              variant="outlined"
              color="secondary"
              onChange={(e) => setStateValue('type_id', e.target.value)}>
              <MenuItem value={-1}>Please select</MenuItem>
              {Object.entries(lists?.event_types || {}).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
            {errors && <Typography variant="errors">{errors?.type_id}</Typography>}
          </FormControl>
        </Grid>
        <Grid item xs={state.type_id == 4 ? 8 : 4}>
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            id="filled-size-normal"
            inputProps={{ min: 0 }}
            color="secondary"
            required
            value={parseInt(state?.guests_number)}
            onChange={(e) => {
              setStateValue('guests_number', parseInt(e.target.value));
            }}
            label={state.type_id != 4 ? 'Minimum # of Guests' : 'Amount of Orders'}
            onWheel={(event) => event.target.blur()}
          />
          {errors && <Typography variant="errors">{errors?.guests_number}</Typography>}
        </Grid>
        {state.type_id != 4 && (
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              color="secondary"
              inputProps={{ min: 0 }}
              disabled={state.type_id == 4}
              value={parseInt(state?.guests_number_expected)}
              onChange={(e) => setStateValue('guests_number_expected', parseInt(e.target.value))}
              label="Final # of Guests"
              id="filled-size-normal"
              onWheel={(event) => event.target.blur()}
            />
            {errors && <Typography variant="errors">{errors?.guests_number_expected}</Typography>}
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            variant="outlined"
            type="text"
            value={state?.notes || ''}
            onChange={(e) => setStateValue('notes', e.target.value)}
            color="secondary"
            label="Notes"
            id="filled-size-normal"
            rows={7}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventInfo;
