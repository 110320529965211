import React, { forwardRef, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Grow,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import { green } from '@mui/material/colors';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const ChangesDialog = ({ handleClose, state, onConfirm, setChangesState, loading, success }) => {
  const classes = useStyles();

  const setStateValue = (field, value) => {
    setChangesState({ ...state, [field]: value });
  };
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };
  return (
    <Dialog
      maxWidth="md"
      open={true}
      onClose={handleClose}
      keepMounted
      className={classes.overflow}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4" className={classes.title}>
          Write the reason of your change
        </Typography>
        <ClearIcon onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent>
        <Box className={classes.wrapper} noValidate autoComplete="off">
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Reason"
                required
                multiline
                color="secondary"
                value={state.reason}
                onChange={(e) => setStateValue('reason', e.target.value)}
              />
            </Grid>
          </Grid>

          <Box className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              className={classes.cancelBtn}
              onClick={handleClose}>
              <Typography variant="subtitle3">Cancel</Typography>
            </Button>

            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={onConfirm}
              disabled={!loading && !state.reason}
              sx={buttonSx}>
              <Typography variant="subtitle4">Submit</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChangesDialog;
