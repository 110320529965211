import React, { useEffect, useState } from 'react';
import { BasicSearch } from 'components';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { useStyles } from './styles';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CustomerPopup from './Dialog/CustomerPopup';
import { useDispatch, useSelector } from 'react-redux';
import BasicPagination from 'components/BasicPagination';
import ResponsiveDialog from 'components/ResponsiveDialog';
import { selectCustomer } from 'store/common/customers/selector';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { deleteCustomerThunk, getCustomerThunk } from 'store/common/customers/thunk';
import api from 'api';
import { CUSTOMERS_API } from 'api/api_routes';
import { deleteCustomer } from 'store/common/customers/action';

const Customers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const { customers } = useSelector(selectCustomer);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  // region local state management
  const [searchQuery, setSearchQuery] = useState('');
  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });

    if (event) event.stopPropagation();
  };
  // endregion

  // region Fetch first page of customers on didMount
  useEffect(() => {
    fetchCustomers(1);
  }, []);
  // endregion

  // region fetch customers by search query
  useEffect(() => {
    setTimeout(() => {
      fetchCustomers(1, searchQuery);
    }, 500);
  }, [searchQuery]);
  // endregion

  // region Actions
  const fetchCustomers = (page = 1) => {
    dispatch(getCustomerThunk({ page, q: searchQuery }));
  };

  const deleteCustomerAction = async (id) => {
    const response = await api.delete(`${CUSTOMERS_API}/${id}`, { onErrors: setErrors });

    if (response.status) {
      dispatch(deleteCustomer(id));
      setShowDeleted(true);
    } else {
      setShowError(true);
      setErrors(response.errors);
    }
    handleDialogState({ delete: false });

    // await dispatch(deleteCustomerThunk(customerId, { onErrors: setErrors }));

    // // if (errors.message)
    // console.log(errors, 'errors');
  };

  // endregion

  return (
    <Card data-tid="staff-page">
      <Grid container className={classes.header} spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={7} md={4} lg={3}>
          <BasicSearch value={searchQuery} onChange={setSearchQuery} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          lg={2}
          sx={{ display: 'flex !important', justifyContent: 'end !important' }}
          className={classes.btnContainer}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => handleDialogState({ add: true })}>
            <AddIcon />
            <Typography variant="h5">Add customer</Typography>
          </Button>
        </Grid>
      </Grid>

      {dialogs.add && (
        <CustomerPopup
          mode="add"
          handleClose={() => handleDialogState({ add: false })}
          onSuccess={() => setShowSuccess(true)}
          // onConfirm={() => handleDialogState({ add: false })}
        />
      )}

      {dialogs.edit !== false && (
        <CustomerPopup
          mode="edit"
          handleClose={() => handleDialogState({ edit: false })}
          onConfirm={() => handleDialogState({ edit: false })}
          onSuccess={() => setShowSuccess(true)}
          customerState={dialogs.edit}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this customer?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteCustomerAction}
          dataId={dialogs.delete}
          // dataId={customerState.id}
        />
      )}

      <TableContainer className={classes.container} data-id="staff-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="tableTitle"> FULL NAME</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> PHONE</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> ACTIONS</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers &&
              customers?.data.map((value) => {
                return (
                  <TableRow
                    hover
                    key={value?.id}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleDialogState({ edit: value })}>
                    <TableCell>
                      <Typography variant="text">{value?.full_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">{value?.email}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="text">{value?.phone_number}</Typography>
                    </TableCell>
                    <TableCell>
                      <ButtonGroup>
                        <Tooltip title="Edit">
                          <IconButton aria-label="Edit">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            color="error"
                            onClick={(e) => handleDialogState({ delete: value.id }, e)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowError(false)}
        open={showError}
        key={'bottom' + 'center'}>
        <Alert severity="error" variant="filled">
          {errors?.message}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowSuccess(false)}
        open={showSuccess}
        key={'bottom' + 'center' + 'success'}>
        <Alert severity="success" variant="filled">
          Your changes were saved successfully!
        </Alert>
      </Snackbar>

      <BasicPagination
        count={customers?.last_page}
        page={customers?.current_page}
        onChange={(_, page) => fetchCustomers(page)}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowDeleted(false)}
        open={showDeleted}
        key={'bottom' + 'center' + 'deleted'}>
        <Alert severity="success" variant="filled">
          The customer was deleted successfully!
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default Customers;
