import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import api from 'api';
import { SETTINGS_UPDATE_API } from 'api/api_routes';
import BasicCheckbox from 'components/BasicCheckbox';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'store/common/auth/selector';
import { initThunk } from 'store/common/auth/thunk';

const AfterEventSms = ({ onSuccess }) => {
  const { settings } = useSelector(selectUser);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    days: settings?.days || 0,
    sms_text: settings?.sms_text || '',
    before_sms_enabled: +(settings?.before_sms_enabled || 0),
  });

  const setStateValues = (field, value) => {
    setState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const onUpdate = async () => {
    const response = await api.post(SETTINGS_UPDATE_API, { settings: state });
    if (response.status) {
      onSuccess && onSuccess();
    }
    await dispatch(initThunk());
  };

  return (
    <Grid container spacing={{ xs: 3 }} sx={{ padding: '25px' }}>
      <Grid item xs={12}>
        <BasicCheckbox
          label="Enabled"
          checked={state.before_sms_enabled}
          onChange={(value) => setStateValues('before_sms_enabled', value ? 1 : 0)}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          type="number"
          label="Days before event"
          value={state.days}
          name="afterEvent"
          color="secondary"
          onChange={(e) => setStateValues('days', e.target.value)}
          placeholder="7"
          onWheel={(event) => event.target.blur()}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          type="text"
          value={state.sms_text}
          multiline
          fullWidth
          label="Message"
          color="secondary"
          onChange={(e) => setStateValues('sms_text', e.target.value)}
          placeholder="Hello {first_name}, your event has ended."
          rows={8}
        />
      </Grid>

      <Grid item xs={12}>
        <Button variant="contained" onClick={onUpdate}>
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default AfterEventSms;
