import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  hide: {
    [theme.breakpoints.down(1200)]: {
      display: 'none !important',
    },
  },

  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
}));
