import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    // [theme.breakpoints.down('md')]: {
    //   maxWidth: '600px',
    //   margin: '0 !important',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: '400px',
    //   margin: '0!important',
    //   padding: '0!important',
    // },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px',
    padding: '6px 10px',
    [theme.breakpoints.down(270)]: {
      alignItems: 'center',
      display: 'inline-block',
    },
  },
  title: {
    [theme.breakpoints.down(580)]: {
      fontSize: '16px !important',
    },
  },
  gridContainer: {
    padding: '20px !important',
    // margin: '20px !important',
    // [theme.breakpoints.down(1400)]: {
    //   display: 'flex  !important',
    //   flexDirection: 'column !important',
    // },
    // [theme.breakpoints.down(578)]: {
    //   maxHeight: 'unset !important',
    // },
  },
  box: {
    // height: '50% !important',
    // width: '50%',
    // maxWidth: '100%',
    // [theme.breakpoints.down(520)]: {
    //   width: '100% !important',
    //   marginTop: '20px!important',
    // },
  },
  content: {
    lineHeight: '2.2em !important',
    // [theme.breakpoints.down(450)]: {
    //   fontSize: '12px !important',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   fontSize: '8px !important',
    // },
  },
  textAttribute: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      opacity: 0.9,
    },
    [theme.breakpoints.down(670)]: {
      fontSize: '12px !important',
    },
    [theme.breakpoints.down(470)]: {
      fontSize: '9px !important',
      padding: '4px !important',
    },
  },
  button: {
    height: '40px',
    [theme.breakpoints.down(620)]: {
      height: '30px !important',
    },
    [theme.breakpoints.down(470)]: {
      height: '27px !important',
      padding: '0 !important',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '10px',
    fontSize: '14px',
  },
  image: {
    [theme.breakpoints.down(1800)]: {
      width: '65px',
    },
    [theme.breakpoints.down(500)]: {
      width: '40px',
    },
  },
}));
