import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  layout: {
    minHeight: '100%',
    paddingTop: '64px',
    display: 'flex',
    width: '100%',
  },
  main: {
    backgroundColor: theme.palette.custom.lightGray,
    height: '100%',
  },
  container: {
    height: '100%',
  },
}));
