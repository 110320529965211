import api from 'api';
import { EVENT_MENU_API } from 'api/api_routes';
import { createMenu, deleteMenu, getMenu, loadMenu, updateMenu } from './action';

export const createMenuThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadMenu());

    const response = await api.post(EVENT_MENU_API, data, { onErrors });

    if (response.status) {
      dispatch(createMenu(response.event_menu));
    }
  };

export const getMenuThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadMenu());

    const response = await api.get(`${EVENT_MENU_API}`, data, { onErrors });

    if (response.status) {
      dispatch(getMenu(response));
    }
  };

export const deleteMenuThunk =
  (id, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadMenu());

    const response = await api.delete(`${EVENT_MENU_API}/${id}`, { onErrors });

    if (response.status) {
      dispatch(deleteMenu(id));
    }
  };

export const updateMenuThunk =
  ({ data, id, onErrors = null }) =>
  async (dispatch) => {
    dispatch(loadMenu());
    const response = await api.put(`${EVENT_MENU_API}/${id}`, data, { onErrors });

    if (response.status) {
      dispatch(updateMenu(response.event_menu));
    }
  };
