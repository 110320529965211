import { createAction } from 'redux-actions';
import {
  CREATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_APPOINTMENT,
  LOAD_APPOINTMENT,
  UPDATE_APPOINTMENT,
} from './actionType';

export const createAppointment = createAction(CREATE_APPOINTMENT);
export const getAppointment = createAction(GET_APPOINTMENT);
export const deleteAppointment = createAction(DELETE_APPOINTMENT);
export const updateAppointment = createAction(UPDATE_APPOINTMENT);
export const loadAppointment = createAction(LOAD_APPOINTMENT);
