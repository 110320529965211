import api from 'api';
import { CHANGES_API } from 'api/api_routes';
import { getChanges, loadChanges } from './action';

export const getChangesThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadChanges());

    const response = await api.get(`${CHANGES_API}`, data, { onErrors });

    if (response.status) {
      dispatch(getChanges(response));
    }
  };
