import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Tooltip,
  IconButton,
  TextField,
} from '@mui/material';
import { selectUser } from 'store/common/auth/selector';
import { useSelector } from 'react-redux';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { BasicDatePicker } from 'components';
import moment from 'moment';

const Filters = ({ setFiltersValue, filters, setFilters, initialState }) => {
  const { lists } = useSelector(selectUser);

  const resetFilter = () => {
    setFilters(initialState);
  };

  const camelCase = (str) => {
    return str && str.charAt(0).toUpperCase() + str.substring(1);
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 1.5 }}>
        <Grid item xs={12} lg={1.8}>
          <FormControl fullWidth>
            <InputLabel id="status-select-label" color="secondary">
              Status
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              labelId="status-select-label"
              label="Status"
              color="secondary"
              value={filters.status_id || ''}
              onChange={(e) => setFiltersValue('status_id', e.target.value)}>
              {Object.entries(lists?.event_statuses || {}).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {camelCase(value)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={2.2}>
          <FormControl fullWidth>
            <InputLabel id="types-select-label" color="secondary">
              Event types
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              labelId="types-select-label"
              label="Event types"
              color="secondary"
              value={filters.type_id || ''}
              onChange={(e) => setFiltersValue('type_id', e.target.value)}>
              {Object.entries(lists?.event_types || {}).map(([key, value]) => {
                return (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <FormControl fullWidth>
            <InputLabel id="orderLabel-label" color="secondary">
              Order by
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              labelId="orderLabel-label"
              label="Order By"
              color="secondary"
              value={filters.order_by}
              onChange={(e) => setFiltersValue('order_by', e.target.value)}>
              <MenuItem value="name">Event name</MenuItem>
              <MenuItem value="type_id">Type</MenuItem>
              <MenuItem value="event_date">Date</MenuItem>
              <MenuItem value="status_id">Status</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={1.8}>
          <FormControl fullWidth>
            <InputLabel id="orderby-select-label" color="secondary">
              Order
            </InputLabel>
            <Select
              MenuProps={{
                disableScrollLock: true,
              }}
              labelId="orderby-select-label"
              label="Order"
              color="secondary"
              value={filters.descending}
              onChange={(e) => setFiltersValue('descending', e.target.value)}>
              <MenuItem value="false">ASC</MenuItem>
              <MenuItem value="true">DESC</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="date"
            color="secondary"
            label="Start Date"
            value={filters?.date_start || ''}
            onChange={(e) => setFiltersValue('date_start', e.target.value)}
            id="filled-size-normal"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <TextField
            fullWidth
            variant="outlined"
            type="date"
            color="secondary"
            label="End Date"
            value={filters?.date_end || ''}
            onChange={(e) => setFiltersValue('date_end', e.target.value)}
            id="filled-size-normal"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={0.4}
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          <Tooltip title="Reset">
            <IconButton onClick={resetFilter}>
              <FilterAltOffIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Filters;

{
  /* <BasicDatePicker
              className="datepicker"
              value={[filters.date_start, filters.date_end]}
              onChange={(e) => {
                if (e) {
                  if (e.length == 1) {
                    setFiltersValue('date_start', e[0].format('YYYY-MM-DD'));
                  } else if (e.length > 1) {
                    setFilters({
                      ...filters,
                      date_start: e[0].format('YYYY-MM-DD'),
                      date_end: e[1].format('YYYY-MM-DD'),
                    });
                  }
                }
              }}
              // clear={clear}
              setFiltersValue={setFiltersValue}
              setFilters={setFilters}
            /> */
}
