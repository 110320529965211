import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    padding: '60px',
    [theme.breakpoints.down(1000)]: {
      padding: '10px !important',
      lineHeight: '40px',
    },
  },
}));
