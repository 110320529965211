import { useEffect } from 'react';
import { GlobalAppRouter } from '../../config/routerConfig';
import { AuthRouter } from '../../router/routes';
import { history } from '../../utils/history';

const NotFound = () => {
  const role = 'admin';

  useEffect(() => {
    setTimeout(
      () => history.push(role ? GlobalAppRouter[role].paths.calendar : AuthRouter.paths.login),
      0
    );
  }, []);

  return <div data-tid="not-found">Not Found</div>;
};

export default NotFound;
