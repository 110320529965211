import React, { useEffect, useState } from 'react';
import { BasicSearch } from 'components';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ResponsiveDialog from 'components/ResponsiveDialog';
import { useStyles } from './styles';
import AddIcon from '@mui/icons-material/Add';
import CalculateIcon from '@mui/icons-material/Calculate';
import EventsDialog from './Dialog';
import EventCalculateDialog from './Calculate';
import { useDispatch, useSelector } from 'react-redux';
import { selectEvent } from 'store/common/events/selector';
import { deleteEventThunk, getEventThunk } from 'store/common/events/thunk';
import BasicPagination from 'components/BasicPagination';
import { Link } from 'react-router-dom';
import { history } from 'utils/history';
import moment from 'moment';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { selectUser } from 'store/common/auth/selector';
import { getCustomerThunk } from 'store/common/customers/thunk';
import { Filters } from '..';

const Events = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { events } = useSelector(selectEvent);
  const { user } = useSelector(selectUser);
  const { data } = useSelector(selectEvent);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  const initialState = {
    q: '',
    status_id: null,
    type_id: null,
    date_start: null,
    date_end: null,
    order_by: 'event_date',
    descending: false,
  };

  // region local state management
  const [filters, setFilters] = useState({
    ...initialState,
    date_start: moment().format('YYYY-MM-DD'),
    date_end: moment().add(30, 'day').format('YYYY-MM-DD'),
    // descending: false,
  });

  const [dialogs, setDialogs] = useState({
    add: false,
    delete: false,
    export: false,
    calculate: false,
  });

  const clear = () => {
    setFilters(initialState);
  };

  const setFiltersValue = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });

    if (event) event.stopPropagation();
  };
  // endregion

  // region Fetch first page of events on didMount
  useEffect(() => {
    fetchEventsAction(1);
    fetchCustomerAction();
  }, []);
  // endregion

  // region fetch events by search query
  useEffect(() => {
    setTimeout(() => {
      fetchEventsAction(1);
    }, 500);
  }, [filters]);
  // endregion

  // region Actions
  const fetchEventsAction = (page = 1) => {
    dispatch(getEventThunk({ page, ...filters }));
  };
  const fetchCustomerAction = () => {
    dispatch(getCustomerThunk());
  };

  const deleteEventAction = async (eventId) => {
    await dispatch(deleteEventThunk(eventId));
    handleDialogState({ delete: false });
    setShowDeleted(true);
    fetchEventsAction();
  };

  const onPopupSuccess = () => {
    setShowSuccess(true);
    fetchEventsAction();
  };
  // endregion

  return (
    <Card data-tid="events-page">
      <Grid container className={classes.header} spacing={{ xs: 2, md: 3, lg: 0 }}>
        <Grid item xs={12} lg={3} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
          <BasicSearch value={filters.q} onChange={(value) => setFiltersValue('q', value)} />
        </Grid>

        <Grid item xs={12} lg={7}>
          <Box className={classes.badges}>
            <Box>
              <Typography variant="badgeTitle"> Booked: </Typography>
              <Typography variant="badgeValue">{data?.stats.booked || 0} </Typography>
            </Box>
            <Box>
              <Typography variant="badgeTitle"> Completed: </Typography>
              <Typography variant="badgeValue">{data?.stats.completed || 0}</Typography>
            </Box>
            <Box>
              <Typography variant="badgeTitle"> Total: </Typography>
              <Typography variant="badgeValue">{data?.stats.total || 0}</Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} lg={2}>
          <Grid item xs={12} lg={12}>
            <div className={classes.buttonWrapper}>
              <Button
                variant="contained"
                // disabled={['3'].indexOf(user.role_id) > -1 || !user.signature}
                onClick={() => handleDialogState({ calculate: true })}>
                <CalculateIcon />
              </Button>

              <Button
                variant="contained"
                className={classes.button}
                disabled={['3'].indexOf(user.role_id) > -1 || !user.signature}
                onClick={() => handleDialogState({ add: true })}>
                <AddIcon />
                <Typography variant="h5" className={classes.text}>
                  Add event
                </Typography>
              </Button>
            </div>
          </Grid>

          <Grid item xs={12}>
            {['3'].indexOf(user.role_id) > -1 ||
              (!user.signature && (
                <Typography
                  sx={{
                    justifyContent: 'flex-end',
                    width: '100%',
                    display: 'flex',
                    color: 'red',
                    fontSize: '12px',
                  }}>
                  Add signature from profile page
                </Typography>
              ))}
          </Grid>
          {/* </Grid> */}
        </Grid>
        <Grid item xs={12} lg={12} sx={{ marginTop: '25px' }}>
          <Filters
            events={events?.data}
            setFiltersValue={setFiltersValue}
            filters={filters}
            setFilters={setFilters}
            initialState={initialState}
          />
        </Grid>
      </Grid>

      {dialogs.add && (
        <EventsDialog
          handleClose={(event, reason) => {
            if (reason && reason == 'backdropClick') {
              return;
            }
            handleDialogState({ add: false });
          }}
          onSuccess={onPopupSuccess}
        />
      )}

      {dialogs.calculate && (
        <EventCalculateDialog
          handleClose={(event, reason) => {
            if (reason && reason == 'backdropClick') {
              return;
            }
            handleDialogState({ calculate: false });
          }}
          //   onSuccess={onPopupSuccess}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this event?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteEventAction}
          dataId={dialogs.delete}
        />
      )}

      <TableContainer className={classes.container} data-id="events-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <Typography variant="tableTitle">EVENT NAME</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="tableTitle">TYPE</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="tableTitle">DATE</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="tableTitle">STATUS</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="tableTitle">ACTIONS</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events &&
              events?.data.map((value) => {
                return (
                  <TableRow
                    sx={{ backgroundColor: value?.row_color }}
                    className={classes.cursor}
                    key={value?.id}
                    onClick={() => history.push(`events/${value.id}`)}
                    hover>
                    <TableCell align="left">
                      <Typography variant="text" className={classes.tableName}>
                        {value?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="text">{value?.type?.name}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="text">
                        {value?.event_date
                          ? moment(value.dateTime).utc(true).local().format('MM.DD.YYYY')
                          : ''}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="text">{value?.status?.name}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <ButtonGroup>
                        <Tooltip title="Edit">
                          <IconButton aria-label="Edit">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            color="error"
                            onClick={(e) => handleDialogState({ delete: value.id }, e)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Export">
                          <a target="_blank" rel="noreferrer" href={value?.agreementURI}>
                            <IconButton
                              aria-label="Export"
                              color="pdf"
                              onClick={(e) => handleDialogState({ export: value.id }, e)}>
                              <PictureAsPdfIcon />
                            </IconButton>
                          </a>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <BasicPagination
        count={events?.last_page}
        page={events?.current_page}
        onChange={(_, page) => fetchEventsAction(page)}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowSuccess(false)}
        open={showSuccess}
        key={'bottom' + 'center' + 'success'}>
        <Alert severity="success" variant="filled">
          Your changes were saved successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowDeleted(false)}
        open={showDeleted}
        key={'bottom' + 'center' + 'deleted'}>
        <Alert severity="success" variant="filled">
          The event was deleted successfully!
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default Events;
