import React, { useEffect, useRef, useState } from 'react';
import api from 'api';
import { EVENTS_API } from 'api/api_routes';
import { useStyles } from './styles';
import { Alert, Box, Card, Snackbar, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import EventsResult from '../EventsResult';
import Contract from '../Events/Contract';
import Customer from '../Events/Customer';
import EventInfo from '../Events/EventInfo';
import Payments from '../Events/Payments';
import ChangesDialog from '../Events/ChangesDialog';
import moment from 'moment';
import Signature from '../Events/Signature';

const EventsEdit = () => {
  const classes = useStyles();
  const [errors, setErrors] = useState(null);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [changeDialog, setChangeDialog] = useState(false);
  const [progress, setProgress] = useState(10);

  const timer = useRef();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  const { id } = useParams();

  useEffect(() => {
    getEvent();
  }, []);

  const getEvent = async () => {
    const response = await api.get(`${EVENTS_API}/${id}`, {}, { onErrors: setErrors });
    if (response.status) {
      setState({
        ...response.event,
        event_date: response.event?.dateTime
          ? moment(response.event?.dateTime)?.utc(true)?.local()?.format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),

        event_start: response.event?.dateTime
          ? moment(response.event?.dateTime)?.utc(true)?.local()?.format('HH:mm:00')
          : moment().format('HH:mm:00'),
      });
    }
  };

  const sendForm = async () => {
    // state.contract.grand_total = grandTotal();
    // state.contract.balance_due = balanceDue();

    let date = moment(`${state.event_date} ${state.event_start}`).utc();
    let data = {
      ...state,

      event_date: state.event_date ? date.format('YYYY-MM-DD') : null,
      event_start: state.event_start ? date.format('HH:mm:ss') : null,
    };

    setErrors(null);
    const response = await api.put(`${EVENTS_API}/${data.id}`, data, {
      onErrors: setErrors,
    });
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
        if (response.status) {
          setChangeDialog(false);
          let localState = { ...state };
          delete localState.reason;
          setState(localState);

          setShowSuccess(true);

          //
        } else if (response.statusCode == 206) {
          setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
          setChangeDialog(true);
          setState(state);
        }
      }, 500);
    }
  };

  return (
    <div>
      {state?.id && (
        <div>
          <Box className={classes.container}>
            <Card className={classes.card}>
              <Typography variant="subtitle1" className={classes.title}>
                Events
              </Typography>
              <EventInfo state={state} setState={setState} errors={errors} />
            </Card>
            <Card className={classes.card}>
              <Typography variant="subtitle1" className={classes.title}>
                Customer
              </Typography>
              <Customer
                allState={state}
                state={state.customer}
                setCustomerState={(customer) => setState({ ...state, customer })}
                errors={errors}
              />
            </Card>
            <Card className={classes.card}>
              <Typography variant="subtitle1" className={classes.title}>
                Pricing Details
              </Typography>

              <Contract
                allState={state}
                state={state.contract}
                setContractState={(contract) => setState({ ...state, contract })}
                errors={errors}
              />
            </Card>

            <Card className={classes.card}>
              <Typography component="span" variant="subtitle1" className={classes.title}>
                Payments
              </Typography>

              <Payments
                state={state}
                setPaymentState={(payments) => setState({ ...state, payments })}
                errors={errors}
              />
            </Card>
            <Card className={classes.card}>
              <Typography component="span" variant="subtitle1" className={classes.title}>
                Customer signature
              </Typography>

              <div>
                <Signature
                  image={state?.signature}
                  clearable={true}
                  onChange={(encodedImage) => {
                    setState({ ...state, signature: encodedImage });
                  }}
                />
              </div>
            </Card>
            <Card className={classes.eventsResult}>
              <EventsResult
                state={state}
                setState={setState}
                onConfirm={sendForm}
                errors={errors}
              />
            </Card>
          </Box>

          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={2000}
            onClose={() => setShowSuccess(false)}
            open={showSuccess}
            key={'bottom' + 'center'}>
            <Alert severity="success" variant="filled">
              Your changes were saved successfully!
            </Alert>
          </Snackbar>

          {changeDialog && (
            <ChangesDialog
              state={state}
              loading={loading}
              handleClose={() => {
                setChangeDialog(false);
                let localState = { ...state };
                delete localState.reason;
                setState(localState);
              }}
              setChangesState={setState}
              onConfirm={sendForm}
              success={success}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default EventsEdit;
