import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: { padding: '25px' },

  button: {
    height: '40px',
    cursor: 'pointer',
  },

  content: {
    overflow: 'auto !important',
    padding: '15px!important',
  },
  overflow: { overflowY: 'hidden!important' },
  textField: {
    background: '#f5f8fa',
    width: '350px !important',
    maxWidth: '400px !important',
    [theme.breakpoints.down('md')]: {
      width: '220px !important',
    },
  },

  cancel: {
    // marginRight: '10px !important',
    background: '#f5f8fa !important',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '60px',
    alignItems: 'center',
    gap: '10px',
  },
  header: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 20px',
    cursor: 'pointer',
  },
}));
