import { handleActions } from 'redux-actions';
import { initialState } from '../../initialState';
import { CREATE_MENU, DELETE_MENU, GET_MENU, LOAD_MENU, UPDATE_MENU } from './actionType';

const initial = initialState.settings;

const reducer = handleActions(
  {
    [LOAD_MENU]: (state) => {
      return {
        ...state,
        isLoad: true,
      };
    },
    [CREATE_MENU]: (state, { payload }) => {
      return {
        ...state,
        event_menus: {
          ...state.event_menus,
          data: [...[payload], ...state.event_menus.data],
        },
        isLoad: false,
      };
    },

    [GET_MENU]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
        event_menus: payload?.event_menus,
        isLoad: false,
      };
    },

    [DELETE_MENU]: (state, { payload }) => {
      return {
        ...state,
        event_menus: {
          ...state.event_menus,
          data: state.event_menus.data.filter((menu) => menu.id != payload),
        },
      };
    },

    [UPDATE_MENU]: (state, { payload }) => {
      return {
        ...state,
        event_menus: {
          ...state.event_menus,
          data: state.event_menus.data.map((menu) => (menu.id == payload.id ? payload : menu)),
        },
      };
    },
  },

  initial
);

export default reducer;
