import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '10px',
    fontSize: '14px',
    height: '40px',
    [theme.breakpoints.down('md')]: {
      height: '35px !important',
    },
  },
  overflow: { overflowY: 'hidden' },
  textField: {
    background: '#f5f8fa',
    width: '350px !important',
    maxWidth: '400px !important',
    [theme.breakpoints.down('md')]: {
      width: '220px !important',
    },
  },
  textAttribute: {
    fontSize: '14px',
    fontWeight: '500',
    color: 'white',
    textTransform: 'capitalize',
    '&:hover': {
      opacity: 0.9,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px !important',
    },
  },
  backBtn: {
    marginRight: '10px !important',
    background: '#f5f8fa !important',
  },
  cancel: {
    float: 'right !important',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '60px',
    alignItems: 'center',
  },
  header: {
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 20px',
    cursor: 'pointer',
  },
}));
