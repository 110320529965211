import { Box } from '@mui/material';
import { CreatePasswordForm } from 'components/forms';
import { useStyles } from './styles';

const CreatePassword = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container} data-tid="login-page">
      <Box className={classes.leftContent}></Box>
      <Box className={classes.rightContent}>
        <CreatePasswordForm />
      </Box>
    </Box>
  );
};

export default CreatePassword;
