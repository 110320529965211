import React from 'react';
import { StyledInputBase, useStyles } from './styles';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';

const BasicSearch = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.search} data-id="basic-search" sx={{ width: '100%' }}>
      <Box className={classes.searchIconWrapper}>
        <SearchIcon />
      </Box>
      <StyledInputBase
        value={value}
        onChange={(e) => {
          onChange && onChange(e.target.value);
        }}
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
      />
    </Box>
  );
};

export default BasicSearch;
