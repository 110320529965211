import React, { forwardRef, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  TextField,
  Grow,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import { useDispatch } from 'react-redux';
import api from 'api';
import { EVENT_MENU_API } from 'api/api_routes';
import { createMenu, updateMenu } from 'store/common/settings/action';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const SettingDialog = ({ handleClose, menuState, onConfirm, mode, onSuccess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);

  const [state, setState] = useState({
    name: '',
    description: '',
    ...menuState,
  });

  const setStateValue = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const sendForm = async () => {
    if (mode == 'add') {
      const response = await api.post(EVENT_MENU_API, state, { onErrors: setErrors });

      if (response.status) {
        dispatch(createMenu(response.event_menu));
        onSuccess && onSuccess();
        if (onConfirm) onConfirm(menuState.id);
        handleClose();
      }
    } else {
      const response = await api.put(`${EVENT_MENU_API}/${state.id}`, state, {
        onErrors: setErrors,
      });

      if (response.status) {
        dispatch(updateMenu(response.event_menu));
        onSuccess && onSuccess();
        if (onConfirm) onConfirm(menuState.id);
        handleClose();
      }
    }
  };

  return (
    <Dialog
      maxWidth="md"
      open={true}
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <Box className={classes.header}>
        <Typography variant="h4">{mode == 'add' ? 'Create Menu' : 'Edit Menu'}</Typography>
        <ClearIcon onClick={handleClose} />
      </Box>

      <Divider />

      <DialogContent>
        <Box className={classes.container} noValidate autoComplete="off">
          <Grid container spacing={{ xs: 2, md: 3 }} className={classes.wrapper}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Name"
                required
                color="secondary"
                type="text"
                value={state?.name}
                onChange={(e) => setStateValue('name', e.target.value)}
              />
              {errors && (
                <Typography variant="errors" style={{ display: 'block' }}>
                  {errors?.name}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                required
                label="Description"
                type="text"
                color="secondary"
                value={state?.description}
                onChange={(e) => setStateValue('description', e.target.value)}
              />
              {errors && (
                <Typography variant="errors" style={{ display: 'block' }}>
                  {errors?.description}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Box className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              className={classes.cancelBtn}
              onClick={handleClose}>
              <Typography variant="subtitle3">Cancel</Typography>
            </Button>
            <Button size="small" variant="contained" color="primary" onClick={sendForm}>
              <Typography variant="subtitle4">{mode == 'add' ? 'Create' : 'Save'}</Typography>
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SettingDialog;
