import React, { useState } from 'react';
import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { useStyles } from '../styles';
import logo from '../../../assets/images/logo.jpg';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { CREATE_PASS_API } from 'api/api_routes';
import api from 'api';
import { history } from 'utils/history';

const CreatePasswordForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [queries] = useSearchParams();
  const [serverErrors, setServerErrors] = useState(null);

  const email = queries.get('email');
  const token = queries.get('token');

  const [state, setState] = useState({
    password: '',
    password_confirmation: '',
  });
  const setStateValue = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const onSubmit = async () => {
    setServerErrors(null);

    const response = await api.post(
      CREATE_PASS_API,
      { ...state, email, token },
      { onErrors: setServerErrors }
    );

    if (response.status) {
      if (history.location.pathname == '/password/forget') {
        history.push('/login');
      }
    }
  };

  return (
    <>
      <Box data-tid="create-password-form" className={classes.wrapper}>
        <img className={classes.logo} alt="logo" src={logo} />
        <Card className={classes.form} autoComplete="off">
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="p" className={classes.textAttribute}>
                Set new Password
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                label="Password"
                required
                color="secondary"
                className={classes.marginBottom}
                id="filled-size-small"
                value={state.password}
                onChange={(e) => setStateValue('password', e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              />

              {serverErrors && (
                <Typography variant="errors">{serverErrors?.password?.[1]}</Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                label="Confirm Password"
                required
                color="secondary"
                className={classes.marginBottom}
                id="filled-size-small"
                value={state.password_confirmation}
                onChange={(e) => setStateValue('password_confirmation', e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              />
              {serverErrors && (
                <Typography variant="errors">{serverErrors?.password?.[0]}</Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" className={classes.continueBtn} onClick={onSubmit}>
                <Typography variant="h5">Continue</Typography>
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
    </>
  );
};

export default CreatePasswordForm;
