import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  clearBtn: {
    marginRight: '10px !important',
  },
  buttonContainer: { display: 'flex', justifyContent: 'flex-end', margin: '20px 0px' },

  container: {
    border: '2px dotted rgba(0,0,0,0.3)',
    borderRadius: '7px',
    overflow: 'hidden',
  },

  image: {
    height: '250px',
    objectPosition: 'center',
    width: '100%',
    objectFit: 'scale-down',
  },
}));
