import React, { useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import './style.scss';
import { calendarColors } from 'constants/calendar';
import { Dialog, DialogContent, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const localizer = momentLocalizer(moment);

const styles = (event) => {
  let backgroundColor = !event.allDay ? '#e0e2e4' : '#029BF3';
  var color = !event.allDay ? '#7E8299' : '#fff';

  if (event.colorId) {
    backgroundColor = calendarColors[event.colorId].background;
    color = calendarColors[event.colorId].foreground;
  }

  return {
    style: {
      backgroundColor: backgroundColor,
      color: color,
      borderRadius: '5px',
      display: 'block',
    },
  };
};

const BasicCalendar = ({ events, onRangeChange, dateState, setDateState }) => {
  const classes = useStyles();
  const [selectedEvent, setSelectedEvent] = useState(null);

  events = events.map((el) => {
    let startDate = el.googleEvent.start.dateTime
      ? new Date(moment.utc(el.googleEvent.start.dateTime).local().format('YYYY/MM/DD HH:mm:ss'))
      : new Date(moment.utc(el.googleEvent.start.date).local().format('YYYY/MM/DD HH:mm:ss'));

    let endDate = el.googleEvent.end.dateTime
      ? new Date(moment.utc(el.googleEvent.end.dateTime).local().format('YYYY/MM/DD HH:mm:ss'))
      : new Date(moment.utc(el.googleEvent.end.date).local().format('YYYY/MM/DD HH:mm:ss'));

    return {
      start: startDate,
      end: endDate,
      title: el.googleEvent.summary,
      creator: el.googleEvent.creator.email,
      allDay: el.googleEvent.start.dateTime == null && el.googleEvent.end.dateTime == null,
      colorId: el.googleEvent.colorId,
    };
  });

  const onNavigate = (date, view) => {
    let range = {
      start: moment(date).startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
      end: moment(date).endOf('month').add(1, 'month').format('YYYY-MM-DD'),
    };

    setDateState && setDateState(date);
    // onRangeChange && onRangeChange(range);
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={styles}
        sx={{ height: '100%', width: '100%', maxWidth: '100%', overflow: 'hidden' }}
        onSelectEvent={(event) => setSelectedEvent(event)}
        date={dateState}
        onNavigate={onNavigate}
      />

      {selectedEvent && (
        <Dialog className={classes.overflow} open={true} onClose={() => setSelectedEvent(null)}>
          <DialogContent className={classes.content}>
            <Box className={classes.header}>
              <div className={classes.wrapper}>
                <CalendarMonthIcon />
                <Typography sx={{ marginLeft: '15px' }} variant="h4">
                  {selectedEvent.title}
                </Typography>
              </div>
              <ClearIcon onClick={() => setSelectedEvent(null)} />
            </Box>

            <Divider />

            <Box className={classes.body}>
              <Typography variant="text">
                <b>Starts:</b> {moment.utc(selectedEvent.start).local().format('YYYY-MM-DD h:mm A')}
              </Typography>
              <Typography variant="text">
                <b>Ends:</b> {moment.utc(selectedEvent.end).local().format('YYYY-MM-DD h:mm A')}
              </Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default BasicCalendar;
