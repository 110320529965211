import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down(820)]: { padding: '0px !important' },
  },

  title: {
    textTransform: 'uppercase',
  },
  padding: {
    padding: '0 30px !important',
    [theme.breakpoints.down(820)]: {
      padding: '0 15px !important',
    },
  },
}));
