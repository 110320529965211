import React, { useEffect, useState } from 'react';
import { BasicSearch } from 'components';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Grid,
  IconButton,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './styles';
import UserPopup from './Dialog/UserPopup';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStaffThunk, getStaffThunk } from 'store/common/staff/thunk';
import { selectStaff } from 'store/common/staff/selector';
import BasicPagination from 'components/BasicPagination';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ResponsiveDialog from 'components/ResponsiveDialog';

const Staff = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { users } = useSelector(selectStaff);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  // region local state management
  const [searchQuery, setSearchQuery] = useState('');
  const [dialogs, setDialogs] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  const handleDialogState = (dialogState, event) => {
    setDialogs({ ...dialogs, ...dialogState });

    if (event) event.stopPropagation();
  };
  // endregion

  // region Fetch first page of users on didMount
  useEffect(() => {
    fetchUsersAction(1);
  }, []);
  // endregion

  // region fetch users by search query
  useEffect(() => {
    setTimeout(() => {
      fetchUsersAction(1, searchQuery);
    }, 500);
  }, [searchQuery]);
  // endregion

  // region Actions
  const fetchUsersAction = (page = 1) => {
    dispatch(getStaffThunk({ page, q: searchQuery }));
  };

  const deleteUserAction = async (userId) => {
    await dispatch(deleteStaffThunk(userId));
    handleDialogState({ delete: false });
    setShowDeleted(true);
  };
  // endregion

  return (
    <Card data-tid="staff-page">
      <Grid container spacing={{ xs: 2, md: 3 }} className={classes.header}>
        <Grid item xs={12} sm={7} md={4} lg={3}>
          <BasicSearch value={searchQuery} onChange={setSearchQuery} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          lg={2}
          sx={{ display: 'flex !important', justifyContent: 'end !important' }}
          className={classes.btnContainer}>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={() => handleDialogState({ add: true })}>
            <AddIcon />
            <Typography variant="h5">Add user</Typography>
          </Button>
        </Grid>
      </Grid>

      {dialogs.add && (
        <UserPopup
          mode="add"
          handleClose={() => handleDialogState({ add: false })}
          onSuccess={() => setShowSuccess(true)}
          // onConfirm={() => handleDialogState({ add: false })}
        />
      )}

      {dialogs.edit !== false && (
        <UserPopup
          mode="edit"
          handleClose={() => handleDialogState({ edit: false })}
          onConfirm={() => handleDialogState({ edit: false })}
          userState={dialogs.edit}
          onSuccess={() => setShowSuccess(true)}
        />
      )}

      {dialogs.delete !== false && (
        <ResponsiveDialog
          label="Are you sure you want to delete this user?"
          onClose={() => handleDialogState({ delete: false })}
          onConfirm={deleteUserAction}
          dataId={dialogs.delete}
        />
      )}

      <TableContainer className={classes.container} data-id="staff-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="tableTitle"> FULL NAME</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle">ROLE</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> EMAIL</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> PHONE</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> ADDRESS</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="tableTitle"> ACTIONS</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users?.data.map((value) => {
                return (
                  <TableRow
                    hover
                    key={value.id}
                    onClick={() => handleDialogState({ edit: value })}
                    className={classes.cursor}>
                    <TableCell>
                      <Typography variant="text">
                        {value.first_name} {value.last_name}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="text">{value.role.name}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="text">{value.email}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="text">{value.phone_number}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="text">{value.address}</Typography>
                    </TableCell>

                    <TableCell>
                      <ButtonGroup>
                        <Tooltip title="Edit">
                          <IconButton aria-label="Edit">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="Delete"
                            color="error"
                            onClick={(e) => handleDialogState({ delete: value.id }, e)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <BasicPagination
        count={users?.last_page}
        page={users?.current_page}
        onChange={(_, page) => fetchUsersAction(page)}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowSuccess(false)}
        open={showSuccess}
        key={'bottom' + 'center'}>
        <Alert severity="success" variant="filled">
          Your changes were saved successfully!
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={2000}
        onClose={() => setShowDeleted(false)}
        open={showDeleted}
        key={'bottom' + 'center' + 'deleted'}>
        <Alert severity="success" variant="filled">
          The staff member was deleted successfully!
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default Staff;
