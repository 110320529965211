import { handleActions } from 'redux-actions';
import { initialState } from '../../initialState';
import {
  CREATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  GET_APPOINTMENT,
  LOAD_APPOINTMENT,
} from './actionType';

const initial = initialState.appointments;

const reducer = handleActions(
  {
    [LOAD_APPOINTMENT]: (state) => {
      return {
        ...state,
        isLoad: true,
      };
    },
    [CREATE_APPOINTMENT]: (state, { payload }) => {
      return {
        ...state,
        appointments: {
          ...state.appointments,
          data: [...[payload], ...state.appointments.data],
        },
        isLoad: false,
      };
    },
    [GET_APPOINTMENT]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
        appointments: payload?.appointments,
        isLoad: false,
      };
    },
    [DELETE_APPOINTMENT]: (state, { payload }) => {
      return {
        ...state,
        appointments: {
          ...state.appointments,
          data: state.appointments?.data.filter((appointment) => appointment?.id != payload),
        },
      };
    },
    [UPDATE_APPOINTMENT]: (state, { payload }) => {
      return {
        ...state,
        appointments: {
          ...state.appointments,
          data:
            state.appointments?.data.map((appointment) =>
              appointment?.id == payload?.id ? payload : appointment
            ) || [],
        },
      };
    },
  },

  initial
);

export default reducer;
