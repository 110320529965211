import api from 'api';
import { STAFF_API } from 'api/api_routes';
import { handleUser } from '../auth/action';
import { createStaff, deleteStaff, getStaff, loadStaff, updateStaff } from './action';

export const createStaffThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadStaff());

    const response = await api.post(STAFF_API, data, { onErrors });
    if (response.status) {
      dispatch(createStaff(response.user));
    }
  };

export const getStaffThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadStaff());

    const response = await api.get(`${STAFF_API}`, data, { onErrors });
    if (response.status) {
      dispatch(getStaff(response));
    }
  };

export const deleteStaffThunk =
  (id, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadStaff());

    const response = await api.delete(`${STAFF_API}/${id}`, { onErrors });

    if (response.status) {
      dispatch(deleteStaff(id));
    }
  };

export const updateStaffThunk =
  ({ data, id, onErrors = null }) =>
  async (dispatch) => {
    dispatch(loadStaff());
    const response = await api.put(`${STAFF_API}/${id}`, data, { onErrors });
    if (response.status) {
      dispatch(updateStaff(response.user));

      if (response.user?.id == 1) {
        dispatch(handleUser(response.user));
      }
    }
  };
