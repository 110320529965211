import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Sidebar from '../Sidebar';
import { useStyles } from './styles';

const Layout = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const classes = useStyles();

  return (
    <Box className={classes.layout}>
      <Sidebar>
        <Box component="main" className={classes.main} sx={{ flexGrow: 1, p: 3 }}>
          <Box open={isDrawerOpen} className={classes.container}>
            <Outlet />
          </Box>
        </Box>
      </Sidebar>
    </Box>
  );
};

export default Layout;
