import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import { TextField } from '@mui/material';

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="+1(000) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormattedInputs({ onChange, value, label, required }) {
  // const [values, setValues] = useState({
  //   textMask: value,
  // });

  const handleChange = (event) => {
    // setValues({
    //   ...values,
    //   [event.target.name]: event.target.value,
    // });

    if (onChange) onChange(event.target.value?.replaceAll(/[+() -]/g, ''));
  };

  return (
    <TextField
      fullWidth
      value={value}
      onChange={handleChange}
      onInput={handleChange}
      label={label}
      required={required}
      name="textMask"
      color="secondary"
      InputProps={{
        inputComponent: TextMaskCustom,
      }}
      variant="outlined"
    />
  );
}
