import { OutlinedInput } from '@mui/material';
import React, { useState } from 'react';

export const InputNumber = ({ initialValue, onChange, name, ...other }) => {
  const [number, setNumber] = useState(initialValue);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // const checkedNumber = value < 0 ? Math.abs(value) : value;

    setNumber(value);
    onChange({ name, value });
  };
  return (
    <OutlinedInput type="number" name={name} value={number} onChange={handleChange} {...other} />
  );
};
