import api from 'api';
import { CREATE_PASS_API, INIT_API, LOGIN_API, LOGOUT_API, RESET_PASS_API } from 'api/api_routes';
import { history } from 'utils/history';
import { loadUser, handleUser, handleLoggedInUser } from './action';

export const loginThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadUser());

    const response = await api.post(LOGIN_API, data, { onErrors });
  };

export const resetPasswordThunk =
  (data, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadUser());

    const response = await api.post(RESET_PASS_API, data, { onErrors });

    if (response.status) {
      // dispatch(handleUser(response.message));
    } else {
      // dispatch(handleUser(response.message));
    }
  };

export const createPasswordThunk =
  (data, token, onErrors = null) =>
  async (dispatch) => {
    dispatch(loadUser());

    const response = await api.post(CREATE_PASS_API, data, token, { onErrors });

    if (response.status) {
      //
    } else {
      //
    }
  };

export const logoutThunk =
  (onErrors = null) =>
  async (dispatch) => {
    const response = await api.post(LOGOUT_API, { onErrors });

    if (response.status) {
      dispatch(handleUser(null));
      history.push('/login');
    }
  };

export const initThunk =
  (onErrors = null) =>
  async (dispatch) => {
    const response = await api.post(INIT_API, {
      onErrors,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    if (response.status) {
      dispatch(handleLoggedInUser(response));
    } else {
      let routes = ['/password/forget'];
      if (routes.indexOf(history.location.pathname) == -1) {
        history.push('/login');
      }
    }
  };
