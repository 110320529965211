import { createTheme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import components from './components';

export const globalTheme = createTheme({
  typography,
  palette,
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 1200,
      lg: 1440,
      xl: 1920,
    },
  },
  paddings: {},
  margins: {},
});
