import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  Grow,
  Tabs,
  Tab,
  Grid,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import { EVENTS_API } from 'api/api_routes';
import api from 'api';
import Contract from '../Contract';
import EventInfo from '../EventInfo';
import Customer from '../Customer';
import TabPanel from 'components/BasicTabs/TabPanel';
import Signature from '../Signature';
import Payments from '../Payments';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { createEvent } from 'store/common/events/action';
import clsx from 'clsx';
import { getStaffThunk } from 'store/common/staff/thunk';
import { selectUser } from 'store/common/auth/selector';
import { formatNumber } from 'utils/formatNumber';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const EventsDialog = ({ handleClose, onSuccess }) => {
  const ref = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector(selectUser);
  const [errors, setErrors] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const setTabValue = (idx) => {
    setCurrentTab(idx);
  };

  useEffect(() => {
    ref.current?.addEventListener('wheel', (e) => e.preventDefault());
  }, []);

  useEffect(() => {
    dispatch(getStaffThunk());
  }, []);

  const [state, setState] = useState({
    name: '',
    event_date: '',
    event_start: '',
    event_end: '',
    guests_number: '',
    guests_number_expected: '',
    status_id: 1,
    type_id: -1,
    notes: '',
    signature: null, //'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
    reason: '',
    version: null,
    customer: {
      full_name: '',
      email: '',
      address: '',
      phone_number: '',
      alt_phone_number: '',
      dl_number: '',
      dl_expire_date: '',
      autoComplete: false,
    },
    contract: {
      cost_per_guest: '',
      balance_due: '',
      service_fee: 18,
      grand_total: '',
      discount: '',
      menu_id: -1,
      service_list: {
        gratitude: '',
        lightning: '',
        security: '',
        cocktail_hour: '',
        valet_parking: '',
        ceremony: '',
        tax: '',
        other: '',

        // New
        security_new: '',
        lightning_new: '',
        led_screen_new: '',
      },
    },
    payments: [
      {
        payment_name: 'deposit',
        payment_type: 'check',
        amount: 0,
        payment_date: moment().format('YYYY-MM-DD'),
      },
    ],
  });

  const customerArr = Object.keys(state.customer);
  const contractArr = Object.keys(state.contract);
  const eventArr = Object.keys(state);

  const allErrors =
    errors && Object.keys(errors).map((el) => el.replaceAll(/customer\.|contract\./g, ''));

  const customer = customerArr?.some((val) => allErrors?.indexOf(val) >= 0);
  const contract = contractArr?.some((val) => allErrors?.indexOf(val) >= 0);
  const event = eventArr?.some((val) => val !== 'signature' && allErrors?.indexOf(val) >= 0);

  const sendForm = async () => {
    state.contract.grand_total = grandTotal(false);
    state.contract.balance_due = balanceDue(false);

    let date = moment(`${state.event_date} ${state.event_start}`).utc();
    let data = {
      ...state,

      event_date: state.event_date ? date.format('YYYY-MM-DD') : null,
      event_start: state.event_start ? date.format('HH:mm:ss') : null,
    };

    const response = await api.post(EVENTS_API, data, { onErrors: setErrors });

    if (response.status) {
      dispatch(createEvent(response.event));
      handleClose();
      onSuccess && onSuccess();
    } else {
      const allErrors =
        response.errors &&
        Object.keys(response.errors).map((el) => el.replaceAll(/customer\.|contract\./g, ''));

      const customer = customerArr?.some((val) => allErrors?.indexOf(val) >= 0);
      const contract = contractArr?.some((val) => allErrors?.indexOf(val) >= 0);
      const event = eventArr?.some((val) => val !== 'signature' && allErrors?.indexOf(val) >= 0);

      switch (currentTab) {
        case 0:
          if (!customer) setCurrentTab(currentTab + 1);
          break;
        case 1:
          if (!event) setCurrentTab(currentTab + 1);
          break;
        case 2:
          if (!contract) setCurrentTab(currentTab + 1);
          break;
      }
    }
  };

  const setStateValue = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const StyledTabs = withStyles({
    root: {
      '& div.MuiTabs-scroller': {
        '& .MuiTabs-flexContainer': {
          display: 'flex !important',
          justifyContent: 'space-around !important',
          alignItems: 'center !important',
          width: '100%',
          marginBottom: '10px !important',
        },
      },
    },
  })(Tabs);

  const payments = state?.payments
    .filter((_, i) => {
      return i !== 0;
    })
    .reduce((acc, value) => {
      return acc + +value?.amount;
    }, 0);

  const grandTotal = (format = true) => {
    let grandTotal = 0;
    let costPerGuest = 0;

    let services = Object.keys(state?.contract?.service_list)
      .filter((key) => ['tax', 'gratitude', 'valet_parking'].indexOf(key) == -1)
      .map((key) => state?.contract?.service_list[key]);

    let serviceList = services.reduce((acc, value) => {
      return acc + +value;
    }, 0);

    serviceList -= Number(state.contract.discount);

    if (state.type_id == 4) {
      costPerGuest = +state?.contract?.cost_per_guest * state.guests_number;
    } else {
      costPerGuest =
        +state?.contract?.cost_per_guest * (state.guests_number_expected || state.guests_number);
    }

    grandTotal = serviceList + Number(costPerGuest);

    if (state.type_id != 4) {
      grandTotal += (grandTotal * Number(state.contract.service_fee)) / 100;
    }

    if (state.contract?.service_list.tax) {
      grandTotal += (grandTotal * Number(state.contract?.service_list.tax)) / 100;
    }

    grandTotal +=
      Number(state.contract?.service_list.gratitude) +
      Number(state.contract?.service_list.valet_parking);

    let num = parseFloat(grandTotal);
    return format ? formatNumber(num) : num;
  };

  const balanceDue = (format = true) => {
    let gt = grandTotal(false);

    let result =
      state.payments?.length &&
      parseFloat(gt - (Number(state.payments[0].amount) + Number(payments)));

    state.contract.grand_total = gt;
    state.contract.balance_due = result;

    return format ? formatNumber(result) : result;
  };

  return (
    <Dialog
      className={classes.dialog}
      maxWidth="md"
      open={true}
      disableEscapeKeyDown
      // disableBackdropClick
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className={classes.wrap}>
        <Box
          className={classes.header}
          sx={{ flexShrink: 0 }}
          disabled={['1', '2'].indexOf(user.role_id) > -1}>
          <Typography variant="h4">Create event</Typography>
          <ClearIcon className={classes.cancel} onClick={handleClose} />
        </Box>

        <Divider />

        <Tabs
          value={currentTab}
          onChange={(e, idx) => setTabValue(idx)}
          aria-label="secondary tabs example"
          textColor="secondary"
          indicatorColor="secondary"
          variant={window.innerWidth < 567 ? 'scrollable' : 'fullWidth'}
          scrollButtons="auto"
          // TabIndicatorProps={{
          //   sx: { backgroundColor: 'unset !important' },
          // }}
          sx={{
            flexShrink: 0,
            overflowX: 'auto !important',
            overflowY: 'hidden !important',
          }}>
          <Tab
            label="Customer"
            className={clsx(classes.tab, customer ? classes.red : classes.blue)}
          />
          <Tab
            label="Event Information"
            className={clsx(classes.tab, event ? classes.red : classes.blue)}
          />
          <Tab
            label="Pricing Details"
            className={clsx(classes.tab, contract ? classes.red : classes.blue)}
          />
          <Tab
            label="Signature"
            className={clsx(
              classes.tab,
              allErrors?.indexOf('signature') > -1 ? classes.red : classes.blue
            )}
          />
        </Tabs>

        <Divider sx={{ marginBottom: '16px' }} />

        <DialogContent sx={{ padding: '20px 24px' }}>
          <Box>
            <TabPanel value={currentTab} index={0}>
              <Customer
                autofill
                allState={state}
                state={state?.customer}
                setCustomerState={(customer) => setState({ ...state, customer })}
                errors={errors}
              />
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <EventInfo state={state} setState={setState} errors={errors} />
            </TabPanel>

            <TabPanel value={currentTab} index={2}>
              <Contract
                allState={state}
                state={state?.contract}
                guestNumber={state?.guests_number}
                setContractState={(contract) => setState({ ...state, contract })}
                errors={errors}
              />
              <Payments
                state={state}
                setPaymentState={(payments) => setState({ ...state, payments })}
                showCalculations
                errors={errors}
              />
            </TabPanel>

            <TabPanel value={currentTab} index={3}>
              <Signature
                image={state.signature}
                clearable={true}
                onChange={(encodedImage) => {
                  setStateValue('signature', encodedImage);
                }}
              />
            </TabPanel>
          </Box>
        </DialogContent>

        <Divider />

        <Box>
          <Grid container spacing={{ xs: 2 }} className={classes.buttonWrapper}>
            <Grid item sx={{ width: '100%' }}>
              <Grid container spacing={{ xs: 1 }}>
                <Grid item xs={6} sm={4}>
                  <Typography sx={{ fontWeight: 600 }}>Balance due:</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Typography sx={{ fontWeight: 600, textAlign: 'right' }}>
                    $ {balanceDue()}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Typography sx={{ fontWeight: 600 }}>Grand Total:</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Typography sx={{ fontWeight: 600, textAlign: 'right' }}>
                    $ {grandTotal()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={classes.actions} sx={{ marginLeft: 'auto' }}>
              <Button
                size="small"
                className={classes.backBtn}
                onClick={() => {
                  currentTab > 0 && setCurrentTab(currentTab - 1);
                }}>
                <Typography variant="subtitle3">Back</Typography>
              </Button>

              <Button size="small" variant="contained" color="primary" onClick={sendForm}>
                <Typography variant="subtitle4">Submit</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Dialog>
  );
};

export default EventsDialog;
