import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Box,
  Grow,
  Grid,
  FormControl,
  InputLabel,
  InputAdornment,
  TextField,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useStyles } from './styles';
import { CALCULATION_API } from 'api/api_routes';
import api from 'api';
import { useDispatch } from 'react-redux';
import { getStaffThunk } from 'store/common/staff/thunk';
import { formatNumber } from 'utils/formatNumber';
import { InputNumber } from '../Contract/InputNumber';

import process from 'process';
import moment from 'moment';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

const EventCalculateDialog = ({ handleClose, onSuccess }) => {
  const ref = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errors, setErrors] = useState(null);

  useEffect(() => {
    ref.current?.addEventListener('wheel', (e) => e.preventDefault());
  }, []);

  const [state, setState] = useState({
    first_name: '',
    guests_number: '',
    discount: '',

    cost_per_guest: '',
    service_fee: 18,

    grand_total: '',
    notes: '',

    service_list: {
      gratitude: 0,
      lightning: 0,
      security: 0,
      cocktail_hour: 0,
      valet_parking: 0,
      ceremony: 0,
      tax: 0,

      // New
      security_new: 0,
      lightning_new: 0,
      led_screen_new: 0,
    },
  });

  const sendForm = async () => {
    var gt = grandTotal(false);
    const response = await api.post(
      CALCULATION_API,
      { ...state, grand_total: gt },
      { onErrors: setErrors }
    );

    if (response.status) {
      setErrors([]);
      let first_name = state.first_name.trim().toLowerCase().replaceAll(' ', '_');
      let date = moment().format('YYYY-MM-DD');
      //   alert(`${name}_estimate.${date}`);

      var link = document.createElement('a');
      link.href =
        process.env.REACT_APP_API_URL +
        `event/download/estimate?name=${first_name}_estimate.${date}.pdf`;
      link.download = 'estimate.pdf';
      link.click();
    }
  };

  const setStateValue = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const setServiceStateValue = (field, value) => {
    setState({
      ...state,
      service_list: {
        ...state.service_list,
        [field]: value,
      },
    });
  };

  const grandTotal = (format = true) => {
    let grandTotal = 0;
    let costPerGuest = 0;

    let services = Object.keys(state?.service_list)
      .filter((key) => ['tax', 'gratitude', 'valet_parking'].indexOf(key) == -1)
      .map((key) => state?.service_list[key]);

    let serviceList = services.reduce((acc, value) => {
      return acc + +value;
    }, 0);

    // serviceList -= Number(state.discount);

    costPerGuest = +state?.cost_per_guest * state.guests_number;

    grandTotal = serviceList + Number(costPerGuest);

    grandTotal += (grandTotal * Number(state.service_fee)) / 100;

    if (state.service_list.tax) {
      grandTotal += (grandTotal * Number(state.service_list.tax)) / 100;
    }

    grandTotal += Number(state.service_list.gratitude) + Number(state.service_list.valet_parking);

    // grandTotal -= Number(state.discount);

    let num = parseFloat(grandTotal);
    return format ? formatNumber(num) : num;
  };

  return (
    <Dialog
      className={classes.dialog}
      maxWidth="md"
      open={true}
      disableEscapeKeyDown
      // disableBackdropClick
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div className={classes.wrap}>
        <Box className={classes.header} sx={{ flexShrink: 0 }}>
          <Typography variant="h4">Price estimation</Typography>
          <ClearIcon className={classes.cancel} onClick={handleClose} />
        </Box>

        <Divider />

        <DialogContent sx={{ padding: '20px 24px' }}>
          <Box>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      name="first_name"
                      color="secondary"
                      id="first_name"
                      onChange={(e) => setStateValue('first_name', e.target.value)}
                      label="Full Name"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.name}
                    />
                  </FormControl>
                  {errors && <Typography variant="errors">{errors['first_name']}</Typography>}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="guests_number" color="secondary">
                      Total Guest Count
                    </InputLabel>
                    <InputNumber
                      name="guests_number"
                      color="secondary"
                      type="number"
                      id="guests_number"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setStateValue(name, value)}
                      label="Total Guest Count"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.guests_number}
                    />
                  </FormControl>
                  {errors && <Typography variant="errors">{errors['guests_number']}</Typography>}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="service_fee" color="secondary">
                      Service Fee
                    </InputLabel>

                    <InputNumber
                      name="service_fee"
                      color="secondary"
                      id="service_fee"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      label="Service Fee"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_fee}
                    />
                  </FormControl>
                  {errors && <Typography variant="errors">{errors['service_fee']}</Typography>}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="cost_per_guest" color="secondary">
                      Cost Per Guest *
                    </InputLabel>
                    <InputNumber
                      name="cost_per_guest"
                      color="secondary"
                      id="cost_per_guest"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Cost Per Guest *"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.cost_per_guest}
                    />
                  </FormControl>
                  {errors && <Typography variant="errors">{errors['cost_per_guest']}</Typography>}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="gratuity" color="secondary">
                      Gratuity
                    </InputLabel>

                    <InputNumber
                      name="gratitude"
                      color="secondary"
                      id="gratuity"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Gratuity"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.gratitude || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">{errors['service_list.gratitude']}</Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="bar" color="secondary">
                      Bar & Bartenders
                    </InputLabel>
                    <InputNumber
                      name="security"
                      color="secondary"
                      id="bar"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Bar & Bartenders"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.security || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">{errors['service_list.security']}</Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="ceremony" color="secondary">
                      Ceremony Onsite
                    </InputLabel>

                    <InputNumber
                      name="ceremony"
                      color="secondary"
                      id="ceremony"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Ceremony Onsite"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.ceremony || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">{errors['service_list.ceremony']}</Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="chair_table_removal" color="secondary">
                      Chair & Table Removal
                    </InputLabel>

                    <InputNumber
                      name="lightning"
                      color="secondary"
                      id="chair_table_removal"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Chair & Table Removal"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.lightning || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">{errors['service_list.lightning']}</Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="cocktail_hour" color="secondary">
                      Cocktail Hour
                    </InputLabel>

                    <InputNumber
                      name="cocktail_hour"
                      color="secondary"
                      id="cocktail_hour"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Cocktail Hour"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.cocktail_hour || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">{errors['service_list.cocktail_hour']}</Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="tax" color="secondary">
                      Tax
                    </InputLabel>

                    <InputNumber
                      name="tax"
                      color="secondary"
                      id="tax"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">%</InputAdornment>}
                      label="Tax"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.tax || ''}
                    />
                  </FormControl>
                  {errors && <Typography variant="errors">{errors['service_list.tax']}</Typography>}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="valet_parking" color="secondary">
                      Valet Parking
                    </InputLabel>

                    <InputNumber
                      name="valet_parking"
                      color="secondary"
                      id="valet_parking"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Valet Parking"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.valet_parking || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">{errors['service_list.valet_parking']}</Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="security_new" color="secondary">
                      Security
                    </InputLabel>

                    <InputNumber
                      name="security_new"
                      color="secondary"
                      id="security_new"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Security"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.security_new || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">{errors['service_list.security_new']}</Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="lightning_new" color="secondary">
                      Special Events Lighting
                    </InputLabel>

                    <InputNumber
                      name="lightning_new"
                      color="secondary"
                      id="lightning_new"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Special Events Lighting"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.lightning_new || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">{errors['service_list.lightning_new']}</Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="led_screen_new" color="secondary">
                      LED Screen
                    </InputLabel>

                    <InputNumber
                      name="led_screen_new"
                      color="secondary"
                      id="led_screen_new"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setServiceStateValue(name, value)}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="LED Screen"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.service_list.led_screen_new || ''}
                    />
                  </FormControl>
                  {errors && (
                    <Typography variant="errors">{errors['service_list.lightning_new']}</Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="discount" color="secondary">
                      Discount
                    </InputLabel>
                    <InputNumber
                      name="discount"
                      color="secondary"
                      type="number"
                      id="discount"
                      inputProps={{ min: 0 }}
                      onChange={({ name, value }) => setStateValue(name, Number(value))}
                      startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      label="Discount"
                      onWheel={(event) => event.target.blur()}
                      initialValue={state.discount}
                    />
                  </FormControl>
                  {errors && <Typography variant="errors">{errors['discount']}</Typography>}
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    {/* <InputLabel htmlFor="discount" color="secondary">
                      Notes
                    </InputLabel> */}
                    <TextField
                      fullWidth
                      multiline
                      variant="outlined"
                      type="text"
                      value={state?.notes || ''}
                      onChange={(e) => setStateValue('notes', e.target.value)}
                      color="secondary"
                      label="Notes"
                      id="filled-size-normal"
                      rows={3}
                    />
                  </FormControl>
                  {errors && <Typography variant="errors">{errors['discount']}</Typography>}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>

        <Divider />

        <Box>
          <Grid container spacing={{ xs: 2 }} className={classes.buttonWrapper}>
            <Grid item sx={{ width: '100%' }}>
              <Grid container spacing={{ xs: 1 }}>
                <Grid item xs={6} sm={4}>
                  <Typography sx={{ fontWeight: 600 }}>Grand Total:</Typography>
                </Grid>
                <Grid item xs={6} sm={8}>
                  <Typography sx={{ fontWeight: 600, textAlign: 'right' }}>
                    $ {grandTotal()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={classes.actions} sx={{ marginLeft: 'auto' }}>
              <Button size="small" variant="contained" color="primary" onClick={sendForm}>
                <Typography variant="subtitle4">Download PDF</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Dialog>
  );
};

export default EventCalculateDialog;
