import React, { forwardRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grow from '@mui/material/Grow';

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

export default function ResponsiveDialog({ dataId, label, children, onClose, onConfirm }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = (e) => {
    if (onClose) onClose(dataId);
  };

  const handleConfirm = (e) => {
    if (onConfirm) onConfirm(dataId);
  };

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        sx={{ height: 'auto !important' }}
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{label}</DialogTitle>
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
